<template>
<div class="py-4 container-fluid">
    <div class="row">
  <div class="card content">
    <div class="col-md-12">
    <h3>Support
    </h3>
    We've pulled together details on services and organisations that offer help and support directly to struggling with their mental health:

    <div class="article">

 
 <br /><br />
<h5>Support Local to you</h5>
<p>Every community has it's own selection of support services. The following resources allow you to search for support in your area.</p>
<ul>
<li><a href="https://www.rethink.org/help-in-your-area/about-services-and-groups/" target="_blank"><strong>Rethink</strong> about services and groups</a></li>
<li><a href="https://www.mind.org.uk/information-support/guides-to-support-and-services/" target="_blank"><strong>Mind</strong> guide to support and services</a></li>
<li><a href="https://hubofhope.co.uk/" target="_blank"><strong>Hub of Hope</strong> find nearby services</a></li>
</ul>

<br><br>
<h5>Types of Support</h5>

<input id="toggle" type="checkbox" checked class="toggle">
  <label for="toggle"><i class="fas fa-briefcase"></i>        Workplace Support</label>
  <div class="expand">
    <section>
      <p>  Find out what support services your employer offers. It may be that they have specific services set up that you could access or they have an Employee Assistance Programme. <br>
    You may be entitled to support you didn’t even know about.</p>
    </section>
  </div>


  <input id="toggle2" type="checkbox" checked class="toggle">
  <label for="toggle2"><i class="fas fa-user-friends"></i>            Friends & Family</label>
  <div class="expand">
    <section>
      <p> Talk to someone you trust. It can be helpful to talk to a relative, friend or partner, they may be able to offer support and may already noticed changes in the way you feel or behave.<br> It may also be helpful to talk to a faith leader or tutor.  </p>
    </section>
  </div>


  
  <input id="toggle3" type="checkbox" checked class="toggle">
  <label for="toggle3"><i class="fas fa-stethoscope"></i>        Your GP</label>
  <div class="expand">
    <section>
      <p>  Your GP is there to support you with your mental health as well as your physical health.<br><br>
    They will be able to talk through your feelings and outline the options available to you, including medication and specialist support services like IAPT. <br><br>
    <strong>Doc ready</strong> – Doc Ready is a tool that helps you prepare for the first time you visit a doctor to discuss your mental health. <br>
    <a href="https://www.docready.org" target="_blank">www.docready.org</a>      </p>
    </section>
  </div>

  
  <input id="toggle4" type="checkbox" checked class="toggle">
  <label for="toggle4"><i class="fas fa-notes-medical"></i>            Improving Access to Psychological Therapy (IAPT)</label>
  <div class="expand">
    <section>
      <p> Improving Access to Psychological Therapy (IAPT) is an NHS service that offers a short-term psychological therapy called cognitive behavioural therapy to people suffering from anxiety, depression and stress. You may be referred to IAPT by your GP, or you can refer yourself providing you are already registered to a GP.  
<br><br> To find details of your local service visit:
    <a href="https://www.nhs.uk/Service-Search/Psychological-therapies-(IAPT)/LocationSearch/10008 " target="_blank">www.nhs.uk/Service-Search/Psychological-therapies-(IAPT)/LocationSearch/10008 </a>   </p>
    </section>
  </div>

  
  <input id="toggle5" type="checkbox" checked class="toggle">
  <label for="toggle5"><i class="fas fa-clinic-medical"></i>            NHS Services</label>
  <div class="expand">
    <section>
      <p>         The NHS offers a number of local mental health services, you can find out what’s available in your area by using the links below.  <br>
       <a href="https://www.nhs.uk/service-search/find-a-psychological-therapies-service/" target="_blank"> www.nhs.uk/service-search/find-a-psychological-therapies-service/ </a><br>
       <a href="https://www.nhs.uk/Service-Search/Mental-health-information-and-support/LocationSearch/330 " target="_blank"> www.nhs.uk/Service-Search/Mental-health-information-and-support/LocationSearch/330  </a><br>    </p>
    </section>
  </div>

  
  <input id="toggle6" type="checkbox" checked class="toggle">
  <label for="toggle6"><i class="fas fa-comment"></i>            Talking Therapy</label>
  <div class="expand">
    <section>
      <p> Talking Therapy / Counselling involves talking to someone who is trained to help you understand and deal with negative feelings. You don’t need to be referred by your GP to benfit from talking therapy.
        Find a therapist using the following resources:  </p>
        <h4>British Association for Counselling & Psychotherapy (BACP)</h4>
        <a href="https://www.bacp.co.uk/search/Therapists" target="_blank"> www.bacp.co.uk/search/Therapists</a>
        <h4>The UK Council for Psychotherapy (UKCP)</h4>
        <a  href="https://www.psychotherapy.org.uk/find-a-therapist/" target="_blank"> www.psychotherapy.org.uk/find-a-therapist/</a>
        <h4>Mind’s guide on how to find a therapist: </h4>
         <a href=" https://www.mind.org.uk/information-support/drugs-and-treatments/talking-therapy-and-counselling/how-to-find-a-therapist/#.XdZqEy-caL8"  target="_blank"> www.mind.org.uk/information-support/drugs-and-treatments/talking-therapy-and-counselling/how-to-find-a-therapist/#.XdZqEy-caL8</a>
      
    </section>
  </div>

  
  <input id="toggle7" type="checkbox" checked class="toggle">
  <label for="toggle7"><i class="fas fa-users"></i>              Support Groups</label>
  <div class="expand">
    <section>
      <p>  Talking to people who have similar experiences & understand what you are feeling can be helpful.  Lots of groups organise social events and arrange special activities to help boost your mood and improve your wellbeing.
        To find groups in your area you can use one of the following serveces.    </p>
        <h4>Rethink Mental Illness </h4>
        <a href="https://www.rethink.org/services-groups/support-groups"  target="_blank">www.rethink.org/services-groups/support-groups</a>

        <h4>Together UK </h4>
        <a href="http://www.together-uk.org/peer-support"  target="_blank">www.together-uk.org/peer-support</a>
        <h4>The National Survivor User Network (NSUN)</h4>
        <a href="https://www.nsun.org.uk/Pages/Category/group-directory"  target="_blank">www.nsun.org.uk/Pages/Category/group-directory</a>
    
    </section>
  </div>

  
  <input id="toggle8" type="checkbox" checked class="toggle">
  <label for="toggle8"><i class="fas fa-globe"></i>            Online Communities</label>
  <div class="expand">
    <section>
      <p>  Mind’s Elefriends – is a free to use online community described as “a safe place to listen, share and be heard.”  <br>
          <a href="https://www.elefriends.org.uk/" target="_blank">www.elefriends.org.uk</a> <br>
          The Big White Wall – is available in some locations via the NHS and offers a wide range of mental health support. <br>
          <a href="https://www.bigwhitewall.com/"  target="_blank">www.bigwhitewall.com</a> </p>
    </section>
  </div>

  
  <input id="toggle9" type="checkbox" checked class="toggle">
  <label for="toggle9"><i class="fas fa-phone-alt"></i>           Help Lines</label>
  <div class="expand">
    <section>
    
          <h4>Samaritans helpline </h4>
          The Samaritans offer emotional support 24 hours a day – in full confidence. 
          Call 116 123 (UK) – it’s FREE or email <a href="mailto:jo@samaritans.org.uk">jo@samaritans.org.uk</a> <br>
          <a href="https://www.samaritans.org/how-we-can-help/contact-samaritan/" target="_blank">www.samaritans.org/how-we-can-help/contact-samaritan</a>
          
          <h4>Crisis Text Line (Shout) </h4>
          If you’re experiencing a personal crisis, are unable to cope and need support. 
          Shout can help with urgent issues such as: 
          <br>• Suicidal thoughts 
          <br>• Abuse or assault 
          <br>• Self-harm 
          <br>• Bullying 
          <br>• Relationship challenges 
          <br>Text Shout to 85258 (UK). <br>
          <a href="https://www.crisistextline.uk/" target="_blank">www.crisistextline.uk</a> <br>
          
          <h4>CALM</h4>
          National helpline for men to talk about any troubles they are feeling.  
          Call 0800 58 58 58 from 5pm-midnight 365 days of the year. <br>
          <a href="https://www.thecalmzone.net/help/get-help" target="_blank">www.thecalmzone.net/help/get-help/</a>    </section>
  </div>

  
  <input id="toggle10" type="checkbox" checked class="toggle">
  <label for="toggle10"><i class="fas fa-book-open"></i>            Online Resources</label>
  <div class="expand">
    <section>
     The internet is full of resources that can support you. Here are some of the most reputable resources:
            <h4>Mental Health Foundation resources:</h4>
            <a href="https://www.mentalhealth.org.uk/your-mental-health/about-mental-health" target="_blank">About mental health?</a> <br>
            <a href="https://www.mentalhealth.org.uk/a-to-z" target="_blank">Mental health A-Z </a> <br>
            <h4>Mind information and support</h4>
            <a href="https://www.mind.org.uk/information-support/types-of-mental-health-problems/" target="_blank">Types of mental health problems </a> <br>
            <a href="https://www.mind.org.uk/information-support/a-z-mental-health/" target="_blank">Mental health A-Z </a> <br>
            <a href="https://www.mind.org.uk/information-support/drugs-and-treatments/" target="_blank">Drugs and treatment </a> <br>
            <a href="https://www.mind.org.uk/information-support/legal-rights/" target="_blank">Legal rights </a> <br>
            <h4>Rethink information and support</h4>
            <a href="https://www.rethink.org/advice-and-information/about-mental-illness/" target="_blank">About mental ill health – conditions and symptoms</a> <br>
            <a href="https://www.rethink.org/advice-and-information/living-with-mental-illness/" target="_blank">Living with mental ill health – medication, treatment and support </a> <br>
            <a href="https://www.rethink.org/advice-and-information/rights-restrictions/" target="_blank">Rights and restrictions</a> <br>
            <h4>NHS information and support</h4>
            <a href="https://www.nhs.uk/conditions/" target="_blank">NHS health A-Z </a>
    </section>
  </div>              
          </div>      </div>      </div>

  </div>




  </div>
</template>

<script>

export default {
  name: "Support",
  components: {},
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};


var coll = document.getElementsByClassName("collapsible");
  var i;
  
  for (i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var content = this.nextElementSibling;
      if (content.style.maxHeight){
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      } 
    });
  }

  </script>


<style scoped>
.content{
  padding:32px;
  background-color: #F7F5F3;
color: #4E4B48;
width:70%;
min-width:700px;
}
@media only screen and (max-width: 1200px) {
  .content{
width:100%;
min-width:auto;
}
      }

      a{
        color:#5F249F;
      }

  input {
  display: none;
  visibility: hidden;
}
label {
  display: block;
  padding: 1em 0.5em;
  text-align: left;
  border-bottom: 1px solid #d2d6da;
  color: #4E4B48;
  font-size:1rem;
  cursor:pointer;
}
label:hover {
  color: #5F249F;

}
label::after {
  font-weight: bold;
  font-size:1rem;
  content: "+";
  vertical-align: text-top;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 3px;
  float:right;
 

}
.expand {
  overflow: hidden;
  transition: height 0.5s;
 padding: 0px 0px 24px 0px;
}
section {
  padding: 0 20px;
}
.toggle:checked + label + .expand {
  height: 0px;
  padding:0px;
}
.toggle:checked + label::after {
  content: "-";
}

.expand h4{
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  font-size:1rem;
  font-weight:600;
  margin:8px 0px;
}

</style>