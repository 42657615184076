<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div id="page">
    <img class="logo" src="https://lumien.io/wp-content/uploads/2019/11/Lumien-Logo-White-NoSpace.png">
    <p>your check in</p>
    <main>
      <header>
      </header>
      <transition name="fade" mode="out-in">
        <loading v-if="!obj.loaded">Loading.... </loading>

        <div v-else-if="obj.SurveyError !== null">
          <h2>{{ obj.SurveyError.title }}</h2>
          {{ obj.SurveyError.message }}
          <button class="question-answer" @click="ReloadPage">Reload</button>
        </div>

        <SurveyBody v-else-if="obj.SurveyData && !obj.complete && obj.loaded" :survey-data="obj.SurveyData"
          :loaded="obj.loaded" @question-completed="OnQuestionCompleted" @survey-completed="OnSurveyCompleted" />

        <SurveyThankYou v-else-if="obj.complete && obj.loaded" />
      </transition>
      <div class="disclaimer">
        <i class="fas fa-lock"></i> All responses are anonymous.
        <a href="your-data.html" target="_blank">Click here to read about how we use your data.</a>
      </div>
    </main>
  </div>
</template>

<script>



import { SurveyService } from "@/api/surveyservice";
import SurveyBody from "@/views/survey/SurveyBody.vue";
import SurveyThankYou from "@/views/survey/SurveyThankYou.vue";
import { reactive, onMounted } from "vue";

export default {
  name: "Survey",
  components: {
    SurveyBody,
    SurveyThankYou,
  },
  props: {
    surveyId: String
  },

  setup(props) {
    const obj = reactive({
      surveyId: null,
      loaded: false,
      currentQuestionNumber: 1,
      SurveyError: null,
      complete: false,
      SurveyData: null,
      AnswerData: {},
    });

    function hasData() {
      return obj.SurveyData !== null;
    }

    function hasError() {
      return obj.SurveyData !== null;
    }

    function totalQuestions() {
      return obj.SurveyData?.Questions?.length ?? null;
    }

    function loadSurvey(surveyId) {
      obj.IsLoading = true;
      console.log(surveyId);
      SurveyService.getSurveyDefinition(surveyId)
        .then((surveyBody) => {
          obj.SurveyData = surveyBody;
          obj.loaded = true;
          if (surveyBody.organisation?.customCSS) {
            this.AddCSSImport(surveyBody.organisation?.customCSS);
          }
        })
        .catch((err) => {
          obj.SurveyError = err;
        })
        .finally(() => {
          obj.IsLoading = false;
        });
    }

    function ReloadPage() {
      location.reload();
    }

    onMounted(() => {
      obj.surveyId = props.surveyId;
      loadSurvey(obj.surveyId);
    });

    return { obj, props };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    //this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    //this.toggleDefaultLayout();
  },

  methods: {
    OnQuestionCompleted() {
      this.obj.currentQuestionNumber = this.obj.currentQuestionNumber + 1;
    },

    OnSurveyCompleted(answers) {
      this.obj.loaded = false;
      SurveyService.submitSurveyAnswers(this.obj.surveyId, answers)
        .then(() => {
          this.obj.loaded = true;
          this.obj.complete = true;
        })
        .catch((err) => {
          this.obj.SurveyError = err;
        })
        .finally(() => {
          this.obj.loaded = true;
          this.obj.complete = true;
        });
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  // padding: auto 20px 80px 80px;
  max-width: 504px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}


h2 {
  color: #fff;
}

.loading-spinner {
  text-align: center;
  width: 100%;
  height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.logo {
  top: 0px;
  left: 0px;
  width: 100px;
  margin: 2rem 0 0 2rem;
}

p {
  margin: 0 0 0 2rem;
}

header {
  margin-right: auto;
  max-width: 400px;
}

footer {
  background-color: #622398;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer .content {
  padding: 0px 8%;
}

#progress-row {
  display: flex;
  margin-top: 15px;
  max-width: 90%;
  align-items: center;
  align-content: space-between;
}

progress {
  flex-grow: 1;
  margin-right: 10px;
}

progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 20px;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #622398;
}

progress[value]::-webkit-progress-bar {
  color: #fff;
  border: 4px solid #fff;
  background-color: #622398;
  border-radius: 10px;
}

progress[value]::-moz-progress-bar {
  color: #fff;
  border: 4px solid #fff;
  background-color: #622398;
  border-radius: 10px;
}

progress[value]::-webkit-progress-value {
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #622398;
}

progress[value]::-moz-progress-value {
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #622398;
}

.disclaimer {
  width: 100%;
  color: #fff;
  font-size: 0.75em;
  box-sizing: border-box;
  padding: 5px;
  line-height: 100%;
  text-align: center;
}

.disclaimer a {
  color: #fff;

}
</style>
