import { SurveyDefinition } from "./models/surveydefinition";

import {
  SurveyAnswers,
  Answer,
  SurveyResponse,
  CategoryCollection,
} from "./models/surveyresponse";
import { ApiError } from "./models/apierror";
import { URLLocations } from "./models/urlLocations";

const urlLocations = new URLLocations();
const reportGeneratorURL = urlLocations.reportGeneratorURL;

export class SurveyService {
  public static getSurveyDefinition(
    surveyId: string
  ): Promise<SurveyDefinition> {
    const surveyUrl = `${reportGeneratorURL}/api/v1/surveys/${surveyId}`;
    return fetch(surveyUrl, { method: "GET" })
      .then((response) => {
        return response.json() as Promise<SurveyDefinition>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static submitSurveyAnswers(
    surveyId: string,
    categories: Record<string, Record<string, string>>
  ): Promise<void> {
    const surveyUrl = `${reportGeneratorURL}/api/v1/surveys/${surveyId}`;
    const surveyAnswers = {} as CategoryCollection;

    for (const category in categories) {
      surveyAnswers[category] = {} as SurveyAnswers;

      for (const answerId in categories[category]) {
        const answer = categories[category][answerId];

        surveyAnswers[category][answerId] = { choice: answer } as Answer;
      }
    }

    const surveyResponseBody: SurveyResponse = {
      Categories: surveyAnswers,
    };

    return fetch(surveyUrl, {
      method: "POST",
      body: JSON.stringify(surveyResponseBody),
    }).then(async (response) => {
      if (response.ok) return Promise.resolve();
      else {
        await response.json().then(() => {
          throw new ApiError("Error", "Something went wrong, please try again");
        });
      }
    });
  }
}
