<template>
  <div v-if="!obj.loaded">
    Loading, please wait....
  </div>
  <div v-if="obj.loaded" id="profile" class="card card-body mt-4 profile name-info">
    <div class="row ">

      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">{{ obj.userData.firstName }} {{
            obj.userData.lastName
          }}</h5>
          <p class="mb-0 font-weight-bold text-sm">{{ obj.userData.email }}</p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="obj.loaded" id="basic-info" class="card mt-4 ">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-6">
          <label class="form-label">First Name</label>
          <input id="firstname" v-model="obj.userData.firstName" class="form-control" type="text"
            placeholder="e.g. Alice" />
        </div>
        <div class="col-6">
          <label class="form-label">Last Name</label>
          <input id="lastname" v-model="obj.userData.lastName" class="form-control" type="text" placeholder="Thompson" />
        </div>
      </div>
      <hr />
      <!-- <div class="row">
          <div class="col-6">
            <label class="form-label mt-2">Email</label>
            <argon-input id="email" type="email" placeholder="example@email.com" />
          </div>
        </div> -->
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Gender</label>
          <select id="choices-gender" v-model="obj.userData.gender" class="form-control" name="choices-gender">
            <option value="">Gender</option>
            <option v-for="option in obj.userData.genderOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Ethnicity</label>
          <select id="choices-ethnicity" v-model="obj.userData.ethnicity" class="form-control" name="choices-ethnicity">
            <option value="">Ethnicity</option>
            <option v-for="option in obj.userData.ethnicityOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Sexuality</label>
          <select id="choices-sexuality" v-model="obj.userData.sexuality" class="form-control" name="choices-sexuality">
            <option value="">Sexuality</option>
            <option v-for="option in obj.userData.sexualityOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Are you open about sexuality</label>
          <select id="choices-opensexuality" v-model="obj.userData.openSexuality" class="form-control"
            name="choices-opensexuality">
            <option value=""></option>
            <option v-for="option in obj.userData.openSexualityOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Religion</label>
          <select id="choices-religion" v-model="obj.userData.religion" class="form-control" name="choices-religion">
            <option value="">Religion</option>
            <option v-for="option in obj.userData.religionOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-6 col-8">
          <label class="form-label mt-2">Committed to/practicing religious teachings – practicing</label>
          <select id="choices-practicingreligion" v-model="obj.userData.practicingReligion" class="form-control"
            name="choices-practicingreligion">
            <option value=""></option>
            <option v-for="option in obj.userData.practicingReligionOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <hr />
      <div class="col-sm-8">
        <div class="row">
          <label class="form-label mt-2">Date of birth</label>
          <div class="col-sm-6">
            <input id="dateOfBirth" v-model="obj.userData.dateOfBirth" class="form-control" type="date"
              placeholder="01/01/2001" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Your postcode</label>
          <input id="location" v-model="obj.userData.postcode" class="form-control" type="text" placeholder="---- ---" />
        </div>
      </div>
      <div class="row">
        <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md"
          @click="SaveAnswers()">Save</argon-button>
      </div>
    </div>
  </div>

  <div v-if="obj.loaded" id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <!-- <label class="form-label">Current password</label>
      <input id="password" class="form-control" type="password" placeholder="Current Password" />
      <label class="form-label">New password</label>
      <input id="new-password" class="form-control" type="password" placeholder="New Password" />
      <label class="form-label">Confirm new password</label>
      <input id="confirm-password" class="form-control" type="password" placeholder="Confirm password" />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul> -->
      <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="sm"
        @click="SendPasswordResetEmail()">Send Password Reset
        Email</argon-button>
    </div>
  </div>

  <div v-if="obj.loaded" id="relationships" class="card mt-4">
    <div class="card-header">
      <h5>Relationships</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Relationship status</label>
          <select id="choices-relationshipstatus" v-model="obj.userData.relationshipStatus" class="form-control"
            name="choices-relationshipstatus">
            <option value="">Relationship status</option>
            <option v-for="option in obj.userData.relationshipStatusOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Dependents</label>
          <select id="choices-dependents" v-model="obj.userData.dependants" class="form-control"
            name="choices-dependents">
            <option value="">Dependents</option>
            <option v-for="option in obj.userData.dependantsOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md"
          @click="SaveAnswers()">Save</argon-button>
      </div>
    </div>
  </div>

  <div v-if="obj.loaded" id="workandeducation" class="card mt-4">
    <div class="card-header d-flex">
      <h5 class="mb-0">Work and Education</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Company Name</label>
          <input id="companyName" v-model="obj.userData.companyName" class="form-control" type="text"
            placeholder="Lumien Inc." />
        </div>
        <div class="col-6">
          <label class="form-label">Team Name</label>
          <input id="teamName" v-model="obj.userData.teamName" class="form-control" type="text" placeholder="HR Dept."
            disabled />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Salary</label>
          <select id="choices-salary" v-model="obj.userData.salary" class="form-control" name="choices-salary">
            <option value="">Salary</option>
            <option v-for="option in obj.userData.salaryOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Level of Education</label>
          <select id="choices-education" v-model="obj.userData.education" class="form-control" name="choices-education">
            <option value="">Level of Education</option>
            <option v-for="option in obj.userData.educationOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label">Job Title</label>
          <input id="jobTitle" v-model="obj.userData.jobTitle" class="form-control" type="text"
            placeholder="e.g. Manager" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Career Experience</label>
          <select id="choices-expericance" v-model="obj.userData.experience" class="form-control"
            name="choices-expericance">
            <option value="">Career Experience</option>
            <option v-for="option in obj.userData.experienceOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
        <div class="col-sm-4 col-6">
          <label class="form-label mt-2">Remote Worker?</label>
          <select id="choices-remote" v-model="obj.userData.remote" class="form-control" name="choices-remote">
            <option value="">Remote Worker</option>
            <option v-for="option in obj.userData.remoteOptions" :key="option" :value="option">{{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md"
          @click="SaveAnswers()">Save</argon-button>
      </div>
    </div>
  </div>

  <!-- <div id="health" class="card mt-4">
    <div class="card-header"> 
      <h5>Health</h5>
      <p class="text-sm">
        Here you can setup and manage your integration settings.
      </p>
    </div>
    <div class="card-body pt-0">
      
    </div>
  </div>   -->
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import { UserService } from '@/api/userservice';
import { UserDetailsResponse } from '@/api/models/userDetailsResponse';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: "SideNavItem",
  components: {
    ArgonButton,
  },

  props: {},
  setup(props) {
    const obj = reactive({
      userData: UserDetailsResponse,
      loaded: false,
    });
    var user = cookies.get("lumienUser");

    onMounted(() => {
      UserService.getUserDetails(user.email).then(response => {
        obj.userData = response;
        obj.loaded = true;
      });
    });


    return { props, obj };
  },

  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {
    SaveAnswers() {
      UserService.setUserDetails(this.obj.userData).then(() => {
        this.$swal({
          title: "Profile info Saved!",
        });
      })
    },

    SendPasswordResetEmail() {
      var auth = getAuth();
      sendPasswordResetEmail(auth, this.obj.userData.email)
        .then(() => {
          this.$swal({
            title: "Email sent, please check your email for further instructions",
          });
        });
    },
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}
</style>
