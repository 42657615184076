<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="signup-content">
        <img class="logo" src="https://lumien.io/wp-content/uploads/2019/11/Lumien-Logo-White-NoSpace.png">

        <div v-if="!obj.submitted" id="signup-form">
          <div v-if="!props.existingUser" id="signup-description">
            <h1>
              Your employer has invited you to join Lumien.
            </h1>
            <p>
              Lumien is a powerful tool that allows you to understand and manage
              your own wellbeing, with weekly check-ins, insights and tips
              delivered to your inbox. Your data is anonymous and only you can
              view it.
            </p>
          </div>
          <div v-else>
            <h1>Please create a password.</h1>
            <p>
              This will allow seamless integration with the new
              <a href="https://lumien.io/introducing-pathways/" target="_blank">pathways platform</a>
              and more exciting features as we continue to improve Lumien
              throughout 2022.
              <br />
              <br />
              You will be able to login directly to your report at
              <a href="https://personal.lumien.co.uk/login" target="_blank">
                personal.lumien.co.uk/login
              </a>
            </p>
          </div>
          <h1>Register for Lumien</h1>
          <div>
            <label class="uk-form-label" for="signup-email">Email</label>
            <div>
              <div class="uk-inline">
                <input id="signup-email" v-model="obj.email" class="uk-input uk-form-width-large" type="email"
                  placeholder="Enter your email address" />
              </div>
              <div>
                <label class="uk-form-label" for="signup-password">
                  Create a Password
                </label>
                <div>
                  <input id="signup-password" v-model="obj.password" class="uk-input uk-form-width-large" type="password"
                    placeholder="Enter a password" />
                </div>
                <label class="uk-form-label" for="signup-password">
                  Re-Enter Password
                </label>
                <div>
                  <input id="signup-password-repeat" v-model="obj.rePassword" class="uk-input uk-form-width-large"
                    type="password" placeholder="Re-Enter password" />
                </div>
                <label class="uk-form-label" for="signup-firstName">
                  First Name
                </label>
                <div>
                  <input id="signup-firstName" v-model="obj.firstName" class="uk-input uk-form-width-large" type="text"
                    placeholder="First Name" />
                </div>
                <label class="uk-form-label" for="signup-lastName">
                  Surname
                </label>
                <div>
                  <input id="signup-lastName" v-model="obj.lastName" class="uk-input uk-form-width-large" type="text"
                    placeholder="Surname" />
                </div>
              </div>
            </div>

            <div v-if="obj.requiresInviteCode" class="uk-margin">
              <label class="uk-form-label" for="signup-invite">Invite Code</label>
              <div>
                <div class="uk-inline">
                  <input id="signup-invite" v-model="obj.inviteCode" class="uk-input uk-form-width-large" type="text"
                    placeholder="Enter Invite Code" />
                </div>
              </div>
            </div>


            <div v-if="obj.RegistrationError" class="uk-padding uk-padding-remove-left">
              <label class="uk-form-label uk-padding">
                <span class="uk-label uk-label-danger">Error: </span>
                {{ obj.RegistrationError.message }}
              </label>
            </div>

            <div class="uk-align-left">
              <button id="register-button" class="uk-button uk-button-default uk-button-large" @click="OnSubmit">
                Register
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 id="submitted-title">Thank you.</h1>
          Check your inbox for your first check-in.
        </div>
        <div id="privacy-policy">
          By signing up, you confirm that you've read and accepted our
          <a href="https://www.lumien.io/privacy-policy-3/" target="_blank">Privacy Policy</a>.
        </div>


      </div>
    </div>
  </div>
</template>

<script>

import { RegistrationService } from "@/api/registrationservice";
import { ApiError } from "@/api/models/apierror";
import { User } from "@/api/models/apiresponse";
import { reactive, onMounted } from 'vue';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: "Register",
  components: {},
  props: {
    companyId: String,
    existingUser: String,
    reportId: String,
  },

  setup(props) {

    const obj = reactive({
      RegistrationError: null,
      OrganisationDetails: null,

      requiresInviteCode: false,
      companyMode: false,
      submitted: false,
      loading: false,
      email: "",
      inviteCode: "",
      password: "",
      firstName: "",
      lastName: "",
      rePassword: "",
    });

    if (props.companyId) {
      obj.companyMode = true;

    }

    onMounted(() => {
      if (obj.companyMode) {
        obj.loading = true;
        RegistrationService.getOrganisationDetails(props.companyId)
          .then((response) => {
            obj.OrganisationDetails = response;
            obj.requiresInviteCode = obj.OrganisationDetails.requireAccessCode;
          })
          .catch(() => {
            this.$router.replace({ name: "Register" });
          })
          .finally(() => {
            obj.loading = false;
          });
      }
    });
    return { props, obj };
  },

  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
  },

  methods: {
    OnSubmit() {

      this.obj.loading = true;
      this.obj.RegistrationError = null;

      if (this.obj.email.trim() === "") {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Blank Email Error",
          "Email can't be blank, please input a valid email address"
        );
        return;
      }

      if (this.obj.password.trim() === "") {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Blank Password Error",
          "Password can't be blank, please input a password"
        );
        return;
      }

      if (this.obj.password !== this.obj.rePassword) {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Passwords Dont Match",
          "Passwords dont match, please re-enter and try again"
        );
        return;
      }

      const passwordErrors = this.validatePassword();
      if (passwordErrors.length > 0) {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Passwords Error",
          passwordErrors[0]
        );
        return;
      }

      if (this.obj.firstName.trim() === "") {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Blank First Name Error",
          "First Name can't be blank, please input a first name"
        );
        return;
      }

      if (this.obj.lastName.trim() === "") {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Blank Surname Error",
          "Surname can't be blank, please input a surname"
        );
        return;
      }

      if (this.obj.inviteCode.trim() === "" && this.obj.companyMode) {
        this.obj.loading = false;
        this.obj.RegistrationError = new ApiError(
          "Invite Code Error",
          "Invite code can't be blank, please input the invite code provided for you."
        );
        return;
      }

      var auth = getAuth();
      createUserWithEmailAndPassword(auth, this.obj.email, this.obj.password)
        .then(() => {
          console.log("Registered");

          RegistrationService.submitRegistration(
            this.obj.email,
            this.props.companyId,
            this.obj.inviteCode,
            this.obj.password,
            this.obj.firstName,
            this.obj.lastName
          )
            .then(() => {
              if (this.reportId) {
                const user = new User();
                user.email = this.email ?? "";
                user.firstName = this.firstName ?? "";
                user.lastName = this.lastName ?? "";
                user.hasPassword = true;
                user.reportId = this.reportId;
                user.isAutherized = true;

                cookies.set("lumienUser", JSON.stringify(user));
                cookies.set("ul", user.userLevel);

                this.$router.replace({
                  name: "Report",
                  params: { reportId: user.reportId },
                });
              } else {
                this.obj.submitted = true;
              }
            })
            .catch((err) => {
              this.obj.RegistrationError = err;
            })
            .finally(() => {
              this.obj.loading = false;
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          const errorMessage = error.message;
          console.log(errorMessage);
          cookies.remove("lumienUser");
          cookies.remove("ul");
          this.loading = false;
        });


    },

    validatePassword() {
      const p = this.obj.password;
      const errors = [];
      if (p.length < 6) {
        errors.push("Your password must be at least 6 characters.");
      }
      if (p.search(/[a-z]/) < 0) {
        errors.push("Your password must contain at least one lowercase letter.");
      }
      if (p.search(/[A-Z]/) < 0) {
        errors.push("Your password must contain at least one UPPERCASE letter.");
      }
      if (p.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one Number.");
      }
      if (errors.length > 0) {
        return errors;
      }
      return [];
    },
  }
}
</script>
<style scoped>
.col1,
.col2,
.max-width {
  border-radius: 20px;
  box-sizing: border-box;
  min-height: 75px;
  margin: 10px;
}

.col1 {
  flex-grow: 1;
  margin: 0 0 50px 0;
}

.col2 {
  flex-grow: 2;
}

.max-width {
  max-width: 50%;
  margin: 0 0 125px 0;
}

p {
  font-size: 1rem;
  display: block;
  margin: 10px 0 25px 0;
}

strong {
  font-size: 1.25rem;
  font-weight: 500;
  display: block;
  margin: 10px 0;
}

h1 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 400;
  display: block;
  margin: 10px 0;
}

.uk-form-label {
  font-size: 1rem;
  color: #fff;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  letter-spacing: 1.1rem,
}

.uk-form-width-large {
  width: 100%;
}


button {
  border: 2px solid #E63888;
  border-radius: 40px;
  display: block;
  background-color: #E63888;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  margin: 0.25rem auto;
  padding: 10px 75px;

}

button:hover {
  background-color: #622398;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

button:active {
  transform: scale(0.9);
}

#privacy-policy {
  padding: 15px 0;
  font-size: 0.75rem;
}

footer {
  grid-column: 1/5;
  grid-row: 6;
  margin: 100px 0;
  align-self: center;
}

#forgot-button {
  border: none;
  display: block;
  background-color: transparent;
  font-size: 0.75rem;
  font-weight: 200;
  color: #fff;
  text-decoration: underline;
  margin: 5rem auto 8rem;
}

input {
  border-radius: 8px;
  padding: 16px;
  border: none;
  margin: 0px 0px 16px 0px;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
}
</style>
