<!-- eslint-disable vue/first-attribute-linebreak -->
<!-- eslint-disable vue/require-default-prop -->
<template>
  <div class="d-flex justify-content-center">
    <div class="signup-container">
      <div class="signup-content">
        <div style="min-height: 100px">
          <img v-if="companyMode" class="logo" :src="companyLogo" />
        </div>
        <img class="logo" src="https://lumien.io/wp-content/uploads/2019/11/Lumien-Logo-White-NoSpace.png">
        <h1>Login</h1>

        <div id="signup-form">
          <div class="">
            <label class="uk-form-label" for="signup-email">your email address</label>
            <div class="">
              <div class="uk-inline">
                <input id="signup-email" v-model="obj.email" class="uk-input uk-form-width-large" type="email"
                  placeholder="Enter your email address" />
              </div>
              <div>
                <label class="uk-form-label" for="signup-password">
                  your password
                </label>
                <div class="">
                  <input id="signup-password" v-model="obj.password" class="uk-input uk-form-width-large" type="password"
                    placeholder="Password" />
                </div>
              </div>
            </div>
            <div id="privacy-policy"></div>
            <div class="uk-align-left">
              <button id="login-button" class="uk-button uk-button-default uk-button-large" @click="OnSubmit">
                Login
              </button>
            </div>
            <div v-if="obj.registrationError" class="uk-padding uk-padding-remove-left">
              <label class="uk-form-label uk-padding">
                <span class="uk-label uk-label-danger">Error:</span>
                {{ obj.registrationError }}
              </label>
            </div>
            <div>
              <button id="forgot-button" @click="ForgotPassword">
                Oops, Forgotten Password?
              </button>
            </div>
          </div>
          <div id="privacy-policy">
            By logging in, you confirm that you've read and accepted our
            <a href="https://www.lumien.io/privacy-policy-3/" target="_blank">Privacy Policy</a>.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserService } from "@/api/userservice";
import { Organisation } from "@/api/models/organisation";
import { RegistrationService } from "@/api/registrationservice";
import { reactive, computed } from 'vue';
import { mapMutations } from "vuex";


import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();



export default {
  name: "LoginForm",
  components: {},
  props: {
    organisationDetails: Organisation,
  },

  setup(props) {

    const obj = reactive({
      registrationError: null,
      requiresInviteCode: false,
      submitted: false,
      loading: false,
      email: "",
      password: "",
    });

    const companyMode = computed(() => {
      return props.companyName != null;
    });

    const companyDisplayName = computed(() => {
      return props.organisationDetails?.name ?? props.companyName;
    });

    const companyLogo = computed(() => {
      return props.organisationDetails?.logoUri ?? "";
    });



    return { props, obj, companyMode, companyDisplayName, companyLogo }
  },
  mounted() {

    cookies.remove("lumienUser");
    cookies.remove("ul");
    
    if (this.CompanyMode) {
      this.loading = true;
      RegistrationService.getOrganisationDetails(this.companyId)
        .then((response) => {
          this.OrganisationDetails = response;
          if (response.customCSS) {
            this.AddCSSImport(response.customCSS);
          }
        })
        .catch(() => {
          // this.$router.replace({ name: "Register" });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    //this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    //this.toggleDefaultLayout();
  },


  methods: {

    ...mapMutations(["toggleDefaultLayout"]),

    OnSubmit() {
      this.loading = true;
      this.RegistrationError = null;

      var auth = getAuth();
      signInWithEmailAndPassword(auth, this.obj.email, this.obj.password)
        .then(() => {
          UserService.login_v2(this.obj.email, "", this.reportId)
            .then((user) => {
              console.log(user);
              cookies.set("lumienUser", JSON.stringify(user));
              cookies.set("ul", user.userLevel);
              this.$router.replace({
                name: "Report",
                params: { reportId: user.reportId },
              });

            })
            .catch((err) => {
              this.obj.registrationError = err;
              cookies.remove("lumienUser");
              cookies.remove("ul");
            })
            .finally(() => {
              this.loading = false;
            });

        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);

          if (errorCode === "auth/user-not-found") {
            UserService.login(this.obj.email, this.obj.password, this.reportId)
              .then((user) => {

                if (user.status == 400) {
                  this.obj.registrationError = "Your login details are incorrect or the report hasn't generated yet, please check again in a few minutes and try again. ";
                  cookies.remove("lumienUser");
                  cookies.remove("ul");
                  return;
                }
                cookies.set("lumienUser", JSON.stringify(user));
                cookies.set("ul", user.userLevel);
                this.$router.replace({
                  name: "NewPassword",
                  params: { reportId: user.reportId },
                });
              })
              .catch((err) => {
                this.obj.registrationError = err;
                cookies.remove("lumienUser");
                cookies.remove("ul");
              })
              .finally(() => {
                this.loading = false;
              });
          }

          const errorMessage = error.message;
          console.log(errorMessage);

          this.obj.registrationError = "Your login details are incorrect, please check and try again.";

          cookies.remove("lumienUser");
          cookies.remove("ul");
          this.loading = false;
        });
    },

    ForgotPassword() {
      this.$router.replace({
        name: "ForgotPassword",
        params: {
          companyId: this.companyId,
          companyName: this.companyName,
        },
      });
    },

    GotoRegistration() {
      // this.$router.push("/register");
    },


  },

}
</script>
<style scoped>
.logo {
  width: 40%;
  margin: 1rem 30% 1.5rem;
  text-align: center;
}


#email-input {
  width: 90%;
}

#signup-description {
  font-size: 16px;
}

@media only screen and (min-width: 1520px) {
  #signup-description {
    max-width: 70%;
  }
}

.signup {
  width: 80%;
  margin: 0 auto;
  color: #fff;
  max-width: 800px;
  text-align: left;
}

.signup-content,
.signup-container {
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
}

input {
  border-radius: 8px;
  padding: 16px;
  border: none;
  margin: 0px 0px 16px 0px;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
}

#signup-title {
  font-size: 35px;
  color: black;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
}

.col1,
.col2,
.max-width {
  border-radius: 20px;
  box-sizing: border-box;
  min-height: 75px;
  margin: 10px;
}

.col1 {
  flex-grow: 1;
  margin: 0 0 50px 0;
}

.col2 {
  flex-grow: 2;
}

.max-width {
  max-width: 50%;
  margin: 0 0 125px 0;
}

p {
  font-size: 1rem;
  display: block;
  margin: 10px 0 25px 0;
}

strong {
  font-size: 1.25rem;
  font-weight: 500;
  display: block;
  margin: 10px 0;
}

h1 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 400;
  display: block;
  margin: 10px 0;
}

.uk-form-label {
  font-size: 1rem;
  color: #fff;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  letter-spacing: 1.1rem,
}

.uk-form-width-large {
  width: 100%;
}


button {
  border: 2px solid #E63888;
  border-radius: 40px;
  display: block;
  background-color: #E63888;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  margin: 0.25rem auto;
  padding: 10px 75px;

}

button:hover {
  background-color: #622398;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

button:active {
  transform: scale(0.9);
}

#privacy-policy {
  padding: 15px 0;
  font-size: 0.75rem;
}

footer {
  grid-column: 1/5;
  grid-row: 6;
  margin: 100px 0;
  align-self: center;
}

#forgot-button {
  border: none;
  display: block;
  background-color: transparent;
  font-size: 0.75rem;
  font-weight: 200;
  color: #fff;
  text-decoration: underline;
  margin: 5rem auto 8rem;
}
</style>


