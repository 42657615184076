export class ObjectivesDTO {
  objectives = [{}];
  teams = [];
  orgTeamName = "";

  public constructor() {
    this.objectives = [
      {
        id: "",
        title: "",
        description: "",
        deadline: "",
        team: "",
        organisationId: 0,
      },
    ];
    this.teams = [];
    this.orgTeamName = "";
  }
}
