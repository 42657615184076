<!-- eslint-disable vue/require-default-prop -->
<template>
  <div class="row">
    <Line v-if="loaded" id="my-chart-id" class="chart" :data="obj.data" :options="obj.options" />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import { onMounted, watch, reactive, computed, ref } from "vue";
import moment from 'moment';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartData
} from 'chart.js'
import { chartData, dataSet } from "@/api/models/apiresponse";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
)
export default {
  name: "LineChart",
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Line },

  props: {
    chartData: Object,
    loaded: Boolean
  },

  setup(props) {
    const obj = reactive({
      data: {
        labels: props.chartData.map(s => (moment(s.date).format("DD MMM YY"))),
        datasets: [
          {
            backgroundColor: [
              '#00c7b1'
            ],
            data: props.chartData.map(s => (s.value))
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.25,
        scales: {
          y: {
        min: 0,
        max: 100,
      
      }
          // yAxes: [
          //   {
          //     gridLines: {
          //       color: "rgba(255, 255, 255, 0.05)",
          //     },
          //     ticks: {
          //       suggestedMin: 0,
          //       suggestedMax: 100,
          //       fontColor: "rgba(255, 255, 255)",
          //       fontSize: 10,
          //       stepSize: 20,
          //       beginAtZero: true,
          //     },
          //   },
          // ],

          // xAxes: [
          //   {
          //     type:'time',
          //     time: {
          //       unit: 'month'
          //     },
          //     gridLines: {
          //       color: "rgba(255, 255, 255, 0.25)",
          //     },
          //     ticks: {
          //       fontColor: "rgba(255, 255, 255)",
          //       fontSize: 10,
          //       stepSize: 1,
          //       beginAtZero: true,
          //     },
          //   },
          // ],
        },






      }

    });

    watch(props, () => {
      
      var labels = props.chartData.map(s => (moment(s.date).format("DD MMM YY")));
      var datas = props.chartData.map(s => (s.value));
      obj.data = {
        labels: labels,
        datasets: [
          {
            label: '',
            backgroundColor: '#00c7b1',
            data: datas
          }
        ]
      };



  });


  return { props, obj }
}





}


</script>
<style>
.measure .line-chart-container,
.chart-container {
  position: relative;
  margin: 0;
}

.chartjs-render-monitor {
  padding: 15px;
}

.line-chart-overlay {
  position: absolute;
  width: 80%;
  height: 100%;
  top: 50px;
  /* chartArea top  */
  left: 0px;
  /* chartArea left */
  display: flex;
  color: white;
  pointer-events: none;
}

rect {
  fill: transparent;
}

text {
  fill: #fff;
}

.graph-data-container {
  width: 100%;
  max-width: 700px;
}
</style>