<template>
  <div v-if="!obj.loaded">
    Loading interventions, please wait....
  </div>
  <div v-if="obj.loaded" id="profile" class="card card-body mt-4 profile name-info">
    <div class="row ">

      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">Your interventions</h5>
      
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Interventions</label>
          <p class="lbl-sml">Recording your any actvities or changes carried out allows Lumien to identify their impact and ROI.</p>
<hr>
          <!-- <a href="learn more" class="learn-more">learn more</a> -->

          <div v-for="intervention in obj.interventions" :key="intervention" class=" mt-2">
            <input v-model="intervention.id" class="form-control" type="hidden" />
            <input v-model="intervention.organisationId" class="form-control" type="hidden" />

            <div class="row">
              <label class="form-label mt-2">Intervention Title</label>
              <input v-model="intervention.title" class="form-control" type="text" />
            </div>

            <div class="row">
              <label class="form-label mt-2">{{ obj.interventionsDTO.orgTeamName }}</label>
              <select id="choices-dependents" v-model="intervention.team" class="form-control" name="choices-dependents">
                <option value="" disabled>Team</option>
                <p class="lbl-sml">Which team/department will this intervention impact?</p>

                <option v-for="option in  obj.interventionsDTO.teams" :key="option" :value="option.teamId">{{
                  option.teamName
                }}
                </option>
              </select>
            </div>

            <div class="row">
              <label class="form-label mt-2">Description</label>
              <textarea v-model="intervention.description" class="form-control"></textarea>
            </div>

            <div class="row">
              <label class="form-label mt-2">Date Of Implementation</label>
              <p class="lbl-sml">This will be used to determine any impact in the Linked Domains following the intervention.</p>

              <input v-model="intervention.dateOfImplementation" type="date" class="col-md-6" />
            </div>

            <div class="row">
              <label class="form-label mt-2 ">Cost</label>
              <p class="lbl-sml">What was the estimated cost of implementation? This could include materials or the time of the individuals who worked on it as well as direct costs.</p>
              <span>£ <input v-model="intervention.cost" type="number" min="0.00" step="0.01" class="col-md-6 cost" /></span>
            </div>

            <div class="row">
              <label class="form-label mt-2">Linked Domain/s</label>
              <p class="lbl-sml">Select all areas of wellbeing that this intervention is addressing. Hold Ctrl (Cmd) to select multiple items.</p>

              <select
id="choices-dependents" v-model="intervention.domainIdsArr" class="form-control"
                name="choices-dependents" multiple>                
                <option v-for="option in  obj.interventionsDTO.domains" :key="option" :value="option.domainId">{{
                  option.domainName
                }}
                </option>
              </select>
            </div>
            <hr>
            <br>
          <br>
          </div>

          <argon-button
class="float-end mt-6 mb-0 value-btn" color="dark" variant="gradient" size="md"
            @click="AddValueBox()">Add another
            intervention</argon-button>

        </div>
      </div>

    


      <div class="row">
        <div class="">
          <argon-button
class="float-start mt-6 mb-0" color="dark" variant="gradient" size="md"
            @click="SaveAnswers()">Save</argon-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { UserService } from '@/api/userservice';
import { InterventionsDTO } from '@/api/models/interventionsDTO'
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";

const { cookies } = useCookies();

export default {
  name: "InterventionsCollection",
  components: {
    ArgonButton,
  },

  props: {},
  setup(props) {
    const obj = reactive({
      interventionsDTO: InterventionsDTO,
      interventions: [],
      loaded: false,
    });

    var user = cookies.get("lumienUser");

    onMounted(() => {
      UserService.getInterventions(user.organisationId).then(response => {
        obj.interventionsDTO = response;
        obj.interventions = response.interventions;
        if (obj.interventions == null || obj.interventions.length == 0) obj.interventions = [{ id: "0", title: "", description: "", dateOfImplementation: "", team: "", organisationId: user.organisationId, cost: "0.00", domainIdsArr: [] }];
        obj.loaded = true;
      });
    });

    return { props, obj };
  },



  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {

    SaveAnswers() {
      var user = cookies.get("lumienUser");
      this.obj.interventionsDTO.interventions = this.obj.interventions;
      UserService.setInterventions(this.obj.interventionsDTO).then(() => {
        UserService.getInterventions(user.organisationId).then(response => {
        this.obj.interventionsDTO = response;
        this.obj.interventions = response.interventions;
        this.obj.loaded = true;
      });
        this.$swal({
          title: "Your changes have been saved!",
        });
      });
    },

    AddValueBox() {
      var user = cookies.get("lumienUser");
      this.obj.interventions.push({ id: "0", title: "", description: "", dateOfImplementation: "", team: "", organisationId: user.organisationId, cost: "0.00", domainIdsArr: [] });
    }
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}

textarea.form-control {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  transition: 0.2s;
  margin: 0px 0px 8px 0px;
}

textarea.form-control:focus,
textarea.form-control:hover {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  background-color: #fff;
  border: 1px solid #d2d6da;
  box-shadow: none;
  transition: 0.2s;
}

.form-label {
  font-size: 0.875rem;
  color: #4E4B48;
  font-weight: 600;
  margin: 8px 0px 0px 0px;
}

.learn-more {
  font-size: 0.75rem;
  text-decoration: underline;
  color: #E63888;
  margin: 8px 0px 0px 0px;
  float: right;
}

.value-btn {
  width: 100%;
  font-size: 0.75rem;
  max-width: 100%;
  border-radius: 8px;
  margin: 0px;
  margin-top: 8px !important;
  background-color: #02B298 !important;
}</style>
