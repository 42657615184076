<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="card wb-score">
    <div v-if="!loaded">
      Loading, please wait....
    </div>
    <div class="graphs">
      <div v-if="loaded">
        <!-- <a class="info" href="https://app.lumien.co.uk/surveys/personal/about.html" target="_blank"><i
            class="fas fa-info-circle"></i></a> -->

        <div class="measure-score">
          <svg class="circle-chart" :class="{ disabled: noScore, stale: staleScore }"
            viewBox="0 0 33.83098862 33.83098862" width="80%" max-width="250px" xmlns="http://www.w3.org/2000/svg">
            <circle class="circle-chart__background" stroke="rgba(95, 36, 159, 0.25)" stroke-width="3" fill="none"
              cx="50%" cy="50%" r="45%" />
            <circle class="circle-chart__circle" stroke="#5F249F" stroke-width="3" :stroke-dasharray="percentageFormat"
              stroke-linecap="round" fill="none" cx="50%" cy="50%" r="45%" />
            <text class="circle-chart__percent" x="50%" y="50%" alignment-baseline="central" text-anchor="middle"
              dominant-baseline="middle" font-size="8" fill="#5F249F">
              {{ scoreLabel }}
            </text>
          </svg>
          <span class="stale-label">
            {{ staleLabel }}
          </span>
        </div>
      </div>

      <div v-if="chartData">
        <LineChart :chart-data="chartData" :loaded="loaded"></LineChart>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LineChart from './LineChart.vue'
import { computed, watch } from 'vue';

export default {
  name: "WellbeingScoreCard",
  components: {
    LineChart
  },

  props: {
    score: Number,
    scoreDate: String,
    chartData: [],
    loaded: Boolean,
  },

  setup(props) {
    const scoreLabel = computed(() => {
      if (!(props.score == null || props.score === undefined))
        return props.score.toString();
      else return "N/A";
    });

    const percentageFormat = computed(() => {
      return props.score + " 100";
    });


    const noScore = computed(() => {
      return props.score == null || props.score === undefined;
    });

    const staleScore = computed(() => {
      return props.scoreDate != null && props.scoreDate !== undefined;
    });

    const staleLabel = computed(() => {
      const scoreDate2 = moment(props.scoreDate);
      if (staleScore.value) return "Last asked " + scoreDate2.format("DD MMM YY");
      else if (props.score === null) return "No data yet";
      else return "";
    });

    return {
      scoreLabel, percentageFormat, noScore, staleScore, staleLabel
    }
  },
};
</script>



<style lang="scss" scoped>
.measure-score {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wb-score {
  background-color: #f7f5f3;
  border: 1px solid #FFE7E7;
  color: #4E4B48;
  text-align: center;
  box-sizing: boder-box;
  padding: 32px;
}

text {
  fill: #622398;
}

.info {
  color: #622398;
  float: right;
}

.circle-chart__percent {
  color: #5F249F;
}

svg {
  max-width: 200px;
}



.stale-label {
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  margin: 15px 0;
  font-style: italic;
}

.disabled {
  filter: opacity(0.5);
}

.locked {
  background-color: #abafb3;
  color: #e1e5ea;
  border-color: #abafb3;
}

.score {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform: rotate(-90deg);
  /* 2, 3 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.5em);
}

.circle-chart__percent {
  font-weight: 800;
  color: #4E4B48;
}
</style>
