<template>
  <div class="card bg-warning h-100">
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <h4 class="">find support</h4>
      </div>
      <div class="my-4">
        <div class="h6" data-bs-toggle="tooltip" data-bs-placement="bottom"
          title="there are many types of support available to you if you are stuggling. ">
          there are many types of support available to you if you are stuggling.
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-5">
          <argon-button full-width="false" color="info" variant="contained" class="mt-4"
            onclick="location.href='/Support'">
            find support
          </argon-button>
        </div>
        <div class="col-md-5">
          <argon-button full-width="false" color="secondary" variant="contained" class="mt-4"
            onclick="location.href='/CrisisSupport'">
            crisis support
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "FindSupportCard",
  components: {
    ArgonButton,
  },
};
</script>
