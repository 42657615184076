<template>
  <div>
    <h2 style="width: 100%" class="question">{{ props.question }}</h2>
    <p>Drag to select your score</p>
    <div class="moodslider">
      <output for="mood" class="output pulsate-fwd">{{ obj.value }}</output>

      <input id="mood" v-model="obj.value" type="range" max="100" class="focused" min="0" />
      <label for="" class="pull-left"><i class="fa fa-sad-tear"></i></label>
      <label for="" class="pull-right"><i class="fa fa-laugh"></i></label>

    </div>

    <div class="skip">
      <input id="skip" v-model="obj.value" type="radio" name="drone" value="" />
      <label for="skip">skip</label>
    </div>
  </div>
</template>
<script>

import { watch, emit, reactive } from 'vue';

export default {
  name: "Slider",
  components: {},

  props: {
    question: {
      type: String,
      default: "",
      required: true
    },
  },

  emits: ["valueUpdated"],

  setup(props, { emit }) {
    const obj = reactive({
      value: null
    });

    watch(() => obj.value, (newVal) => {
      emit("valueUpdated", { newVal });
    });


    watch(() => props.loaded, () => {
      obj.value = null;
    });

    return { props, obj }
  },
}
</script>
<style lang="scss">
.moodslider {
  margin: 64px 0 128px 0;
}

input {
  padding: 0px;
}


input.focused {
  width: 100%;
  display: block;
  margin: 32px 0 8px;
  padding: 0px;
  color: #00C7B1;
}

.range {
  display: inline-block;
  width: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  border: none;
  border-radius: 16px;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  border: none;
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;

  border: none;
  height: 32px;
  width: 32px;
  border-radius: 510%;
  background: #00C7B1;
  border: 4px solid #fff;
  margin-top: -8px;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  border: none;
  height: 32;
  width: 32;
  border-radius: 510%;
  background: #00C7B1;
  border: 4px solid #fff;
  margin-top: -5px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover,
input[type="range"]::-webkit-slider-thumb:active {
  height: 32px;
  width: 32px;
  margin-top: -8px;
  border: 8px solid #fff;
}

input[type="range"]::-moz-range-thumb:hover,
input[type="range"]::-moz-range-thumb:active {
  height: 25px;
  width: 25px;
  margin-top: -8px;
  border: 4px solid #fff;
}

.focused::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px rgba(98, 35, 152, 0.15);
  transition: 0.2s;
}

.focused::-moz-range-thumb {
  box-shadow: 0 0 0 3px rgba(98, 35, 152, 0.15);
  transition: 0.2s;
}

.focused::-webkit-slider-thumb:hover,
.focused::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 10px rgba(98, 35, 152, 0.15);
  transition: 0.2s;
}

.focused::-moz-range-thumb:hover,
.focused::-moz-range-thumb:active {
  box-shadow: 0 0 0 10px rgba(98, 35, 152, 0.15);
  transition: 0.2s;
}

.clicked::-webkit-slider-thumb {
  -webkit-transform: scale(1.5);
}

.clicked::-moz-range-thumb {
  -moz-transform: scale(1.5);
}

.disabled::-webkit-slider-thumb {
  -webkit-transform: scale(0.9);
  box-shadow: 0 0 0 3px #622398;
  background: #595959 !important;
  border-color: #595959 !important;
}

.disabled::-moz-range-thumb {
  -moz-transform: scale(0.9);
  box-shadow: 0 0 0 3px #622398;
  background: #595959 !important;
  border-color: #595959 !important;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {

  background: #fff !important;
}

input[type="range"].disabled::-webkit-slider-runnable-track {
  background: #00C7B1 !important;
}

input[type="range"].disabled::-moz-range-track {
  background: #00C7B1 !important;
}

.rangeM input[type="range"]::-webkit-slider-thumb {
  background: #00C7B1;
  border-color: #00C7B1;
}

.rangeM input[type="range"]::-moz-range-thumb {
  background: #622398;
  border-color: #622398;
}

.range:hover input[type="range"]:before {
  color: #00C7B1;
  content: "50";
  position: absolute;
  top: -49px;
  background: #622398;
  padding: 8px 0 5px;
  font-size: 14px;
  width: 30px;
  text-align: center;
  border-radius: 100% 100% 0 0;
}

.range:hover input[type="range"]:after {
  content: "";
  position: absolute;
  top: -19px;
  left: 50px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 8px solid #622398;
}

.output {
  display: block;
  text-align: center;
  font-size: 2em;
  color: #fff;
  height: 96px;
  width: 96px;
  box-sizing: border-box;
  border: 8px solid #fff;
  border-radius: 50px;
  padding: 16px 0 0 0;
  margin: 32px auto;
  font-family: "Fredoka One", sans-serif;
}

.pull-left {
  float: left;
  font-size: 1.5rem;
  color: #fff;
}

.pull-right {
  float: right;
  font-size: 1.5rem;
  color: #fff;
}



.skip {
  position: relative;
  float: left;
}

.skip label {
  display: block;
  background-color: transparent;
  color: #622398;
  border-radius: 10px;
  padding: 11px 20px;
  margin: 0 auto 5px auto;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  border: 1px solid transparent;
}

.skip label:hover {
  transform: scale(0.98);
  transition: 0.2s;
}

.skip label:active {
  transform: scale(0.95);
  transition: 0.2s;
}

.skip input[type="radio"] {
  display: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.skip input[type="radio"]:checked+label {
  background: transparent;
  border: 1px solid #ff578a;
  color: #ff578a;
}
</style>
