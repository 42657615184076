<template>
  <div class="container-fluid ">
    <div class="row">
      <div class="col-lg-3 ">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-lg-0 mt-4">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>

import SideNav from "./components/SideNav.vue";
import SideNavItem from "./components/SideNavItem.vue";
export default {
  name: "ProfileInfo",
  components: {
    SideNav,
    SideNavItem,
  },
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
   background:rgba(95, 36, 159, 0.8) !important;
   backdrop-filter: blur(10px) !important;
  }

</style>