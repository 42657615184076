export class ApiError extends Error {
  
  status: number | null = null;
  title: string;

  constructor(title: string, message: string, statusCode?: number) {
    super(message);
    this.title = title;
    this.status = statusCode ?? null;
  }
}
