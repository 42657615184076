<template>
  <div v-if="!obj.loaded">
    Loading, please wait....
  </div>
  <div v-if="obj.loaded" id="companyReport" class="companyReport">
    <select @change="clickReportLink($event)">
      <option v-for="option in obj.reportLinks" :key="option" :value="option.value">{{ option.text }}</option>
    </select>
    <iframe id="inlineFrameExample" title="Lumien Report" width="100%" height="750" :src="obj.currentReportLink">
    </iframe>
  </div>
</template>

<script>
import { UserService } from '@/api/userservice';
import { UserDetailsResponse } from '@/api/models/userDetailsResponse';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: "SideNavItem",
  components: {

  },

  props: {},
  setup(props) {
    const obj = reactive({
      userData: UserDetailsResponse,
      loaded: false,
      reportLinks: [],
      currentReportLink: ""
    });
    var user = cookies.get("lumienUser");

    onMounted(() => {
      UserService.getOrgReportIdString(user.organisationId).then(response => {
        const reportIdString = response.reportIdString;

        // Create an array of all the months in the year, in the shortened format
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get the current date
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        today.setDate(today.getDate() + 4);

        // Get the current year
        let year = today.getFullYear();

        // Create an array of dates with month and year
        let dates = months.map(month => new Date(`${month} ${year}`));

        // Set a start date as Feb 2023
        let startDate = new Date("Jan 2023");

        // Filter the dates array by only dates from Feb 2023 to now
        let filteredDates = dates.filter(date => date >= startDate && date <= today).reverse();


        filteredDates.forEach(filteredDate => {
          // https://personal.lumien.co.uk/reports/LRMED21asb1405Jan23

          var filteredDateText = filteredDate.toLocaleString("default", { month: "long", year: "2-digit" });
          var shortFilteredDateText = filteredDate.toLocaleString("default", { month: "short", year: "2-digit" }).replace(" ", "");

          var optionValue = "https://personal.lumien.co.uk/reports/" + reportIdString + shortFilteredDateText;
          obj.reportLinks.push({ text: filteredDateText, value: optionValue });

        });
        obj.currentReportLink = "https://personal.lumien.co.uk/reports/" + reportIdString + filteredDates[0].toLocaleString("default", { month: "short", year: "2-digit" }).replace(" ", "");
        obj.loaded = true;

      });
    });



    return { props, obj };
  },

  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {

    clickReportLink(reportLink) {
      this.obj.currentReportLink = reportLink.target.value;
    }
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}
#inlineFrameExample{
position:fixed;
width:84vw;
height:88vh;
top:12vh;
left:16vw;
right:0px;

}
</style>
