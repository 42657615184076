<template>
  <div v-if="!obj.loaded">
    Loading objectives, please wait....
  </div>
  <div v-if="obj.loaded" id="profile"  class="card card-body mt-4 profile name-info">
    <div class="row ">
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">Your objectives</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Objectives</label>
          <!-- <a href="learn more" class="learn-more">learn more</a> -->
          <p class="lbl-sml">Recording your objectives allows Lumien to identify which objectives will be most impacted by the wellbeing of your staff, allowing you to better prioritise issues.</p>

          <hr>

          <div v-for="objective in obj.objectives" :key="objective" class="mt-2">
            <input v-model="objective.id" class="form-control" type="hidden" />
            <input v-model="objective.organisationId" class="form-control" type="hidden" />

            <div class="row">
              <label class="form-label mt-2">Title</label>
              <input v-model="objective.title" class="form-control" type="text" />
            </div>

            <div class="row">
              <label class="form-label mt-2">Description</label>
              <textarea v-model="objective.description" class="form-control"></textarea>
            </div>

            <div class="row">
              <label class="form-label mt-2">Deadline</label>
              <p class="lbl-sml">When are you due to complete this objective?</p>
              <input v-model="objective.deadline" type="date" class="col-md-6" />
            </div>


            <div class="row">
              <label class="form-label mt-2">{{ obj.objectivesDTO.orgTeamName }}</label>
              <p class="lbl-sml">Which team/department does this objective belong to?</p>
              <select id="choices-dependents" v-model="objective.team" class="form-control" name="choices-dependents">
                <option value="" disabled>Team</option>
                <option v-for="option in  obj.objectivesDTO.teams" :key="option" :value="option.teamId">{{ option.teamName
                }}
                </option>
              </select>
            </div>
            <hr><br><br>

          </div>
          <argon-button
class="float-end mt-6 mb-0 value-btn" color="dark" variant="gradient" size="md"
            @click="AddValueBox()">Add another
            objective</argon-button>
        </div>
      </div>

   


      <div class="row">
        <div class="">
          <argon-button
class="float-start mt-6 mb-0" color="dark" variant="gradient" size="md"
            @click="SaveAnswers()">Save</argon-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { UserService } from '@/api/userservice';
import { ObjectivesDTO } from '@/api/models/objectivesDTO'
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";

const { cookies } = useCookies();

export default {
  name: "ObjectivesCollection",
  components: {
    ArgonButton,
  },

  props: {},
  setup(props) {
    const obj = reactive({
      objectivesDTO: ObjectivesDTO,
      objectives: [],
      loaded: false,
    });

    var user = cookies.get("lumienUser");

    onMounted(() => {
      UserService.getObjectives(user.organisationId).then(response => {
        obj.objectivesDTO = response;
        obj.objectives = response.objectives;
        if (obj.objectives == null || obj.objectives.length == 0) obj.objectives = [{ id: "0", title: "", description: "", deadline: "", team: "", organisationId: user.organisationId }];
        obj.loaded = true;
      });
    });

    return { props, obj };
  },



  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {

    SaveAnswers() {
      this.obj.objectivesDTO.objectives = this.obj.objectives;
      UserService.setObjectives(this.obj.objectivesDTO).then(() => {
        this.$swal({
          title: "Your changes have been saved!",
        });
      });
    },

    AddValueBox() {
      var user = cookies.get("lumienUser");
      this.obj.objectives.push({ id: "0", title: "", description: "", deadline: "", team: "", organisationId: user.organisationId });
    }
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}

textarea.form-control {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  transition: 0.2s;
  margin: 0px 0px 8px 0px;
}

textarea.form-control:focus,
textarea.form-control:hover {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  background-color: #fff;
  border: 1px solid #d2d6da;
  box-shadow: none;
  transition: 0.2s;
}

.form-label {
  font-size: 0.875rem;
  color: #4E4B48;
  font-weight: 600;
  margin: 8px 0px 0px 0px;
}

.learn-more {
  font-size: 0.75rem;
  text-decoration: underline;
  color: #E63888;
  margin: 8px 0px 0px 0px;
  float: right;
}

.value-btn {
  width: 100%;
  font-size: 0.75rem;
  max-width: 100%;
  border-radius: 8px;
  margin: 0px;
  margin-top: 8px !important;
  background-color: #02B298 !important;
}
.content{
  padding:32px;
}

</style>
