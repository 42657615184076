<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <section v-if="props.loaded" class="survey-body">
    <h2 style="color:#fff;">{{ props.surveyData?.Title }}</h2>
    <transition name="fade"> </transition>
    <transition name="fade" mode="out-in" @after-leave="afterLeave()">
      <div :key="currentQuestion.id" ref="QuestionDiv">
        <component :is="currentQuestionHeaderComponent" :company-name="organisationName"></component>
        <component :is="currentQuestionComponent" :question="currentQuestion.text" :choices="currentQuestion.choices"
          :optional="currentQuestion.optional" :category="currentQuestion.category" @value-updated="AnswerUpdated">
        </component>
        <button class="question-answer" :disabled="!hasAnswer" @click="OnNext()">
          next
        </button>

      </div>
    </transition>
  </section>
</template>

<script>

import { SurveyDefinition } from "@/api/models/surveydefinition";
import Slider from "@/views/survey/components/Slider.vue";
import Radio from "@/views/survey/components/Radio.vue";
import SurveyQuestionHeader from "@/views/survey/components/CategoryComponents/SurveyQuestionHeader.vue";
import MetadataQuestionHeader from "@/views/survey/components/CategoryComponents/MetadataQuestionHeader.vue";
import OrganisationQuestionHeader from "@/views/survey/components/CategoryComponents/OrganisationQuestionHeader.vue";

import { reactive, computed, emit } from 'vue';

export default {
  name: "SurveyBody",
  components: {
    Slider,
    Radio,
    SurveyQuestionHeader,
    MetadataQuestionHeader,
    OrganisationQuestionHeader,
  },

  props: {
    surveyData: SurveyDefinition,
    loaded: Boolean,
  },

  emits: ["SurveyCompleted", "QuestionCompleted"],

  setup(props) {
    const obj = reactive({
      currentQuestionIndex: 0,
      questions: props.surveyData.questions,
      categories: [{}],
      answers: [{}],
    });

    if (obj.questions != null) {
      obj.questions = obj.questions.sort((q1, q2) => q1.questionOrder > q2.questionOrder ? 1 : -1);
    }

    const currentQuestion = computed(() => {
      return obj.questions[obj.currentQuestionIndex];
    });

    const hasAnswer = computed(() => {
      return obj.answers[obj.questions[obj.currentQuestionIndex].id] !== undefined;
    });

    const isLastQuestion = computed(() => {
      return obj.currentQuestionIndex + 1 >= obj.questions.length;
    });

    const organisationName = computed(() => {
      return props.surveyData.organisation?.name ?? "";
    });

    const currentQuestionComponent = computed(() => {
      if (obj.questions[obj.currentQuestionIndex].type === "MultipleChoice") {
        return "Radio";
      } else {
        return "Slider";
      }
    });

    const currentQuestionHeaderComponent = computed(() => {
      if (obj.questions[obj.currentQuestionIndex].category === "OrgMetadata") {
        return "OrganisationQuestionHeader";
      } else if (obj.questions[obj.currentQuestionIndex].category === "GlobalMetadata") {
        return "MetadataQuestionHeader";
      } else {
        return "SurveyQuestionHeader";
      }
    });

    return { props, obj, currentQuestion, hasAnswer, isLastQuestion, organisationName, currentQuestionComponent, currentQuestionHeaderComponent };
  },

  methods: {
    AnswerUpdated(answer) {
      this.obj.answers[this.currentQuestion.id] = answer.newVal;
      if (!(this.currentQuestion.category in this.obj.categories)) {
        this.obj.categories[this.currentQuestion.category] = {}
      }

      this.obj.categories[this.currentQuestion.category][this.currentQuestion.id] = answer.newVal;
    },

    OnNext() {
      const hasAnswer =
        Object.keys(this.obj.answers).find(
          (key) => key === this.currentQuestion.id
        ) !== undefined;
      if (hasAnswer) {
        if (this.isLastQuestion) this.SurveyCompleted();
        else this.QuestionCompleted();
      }
    },

    afterLeave() {
      this.$el.scrollIntoView();
    },

    QuestionCompleted() {
      this.obj.currentQuestionIndex++;
      this.$emit("QuestionCompleted", "");
    },

    SurveyCompleted() {
      this.$emit("SurveyCompleted", this.obj.categories);
      return this.obj.categories;
    },
  }

}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}

.survey-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 130px;
}

button,
.question-answer {
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.question-answer {
  float: right;
}

button:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

button:active:enabled {
  transform: scale(0.9);
}

button:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}
</style>


