import { ApiError } from "./models/apierror";
import { DomainResult } from "./models/apiresponse";
import { URLLocations } from "./models/urlLocations";
import { WellbeingReport } from "./models/wellbeingReport";

const urlLocations = new URLLocations();
const reportGeneratorURL = urlLocations.reportGeneratorURL;

export class ReportService {
  public static getWellbeingScore(reportId: string): Promise<number> {
    if (reportId == "") reportId = "1";
    const reportUrl = `${reportGeneratorURL}/api/v3/getWellBeingScore/${reportId}`;

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<number>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getReport(reportId: string): Promise<WellbeingReport> {
    if (reportId == "") reportId = "1";

    const reportUrl = `${reportGeneratorURL}/api/v3/getReport/${reportId}`;

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<WellbeingReport>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      });
  }

  public static getSubdomains(
    reportId: string,
    domainTitle: string
  ): Promise<DomainResult> {
    if (reportId == "") reportId = "1";
    const reportUrl = `${reportGeneratorURL}/api/v3/getSubdomains/${reportId}/${domainTitle}`;

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<DomainResult>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      });
  }
}
