<template>
  <div>
    <template v-if="IsWellbeing"> In the last week </template>
    <h2 style="width: 100%" class="question">{{ question }}</h2>

    <div v-for="choice in choices" :key="choice.text" class="radiobtn">
      <input :id="choice.text" v-model="obj.value" type="radio" name="drone" :value="choice.text" />
      <label :for="choice.text">{{ choice.text }}</label>
    </div>
  </div>
  <div class="skip">
    <input id="skip" v-model="obj.value" type="radio" name="drone" value="" />
    <label for="skip">skip</label>
  </div>
</template>
<script>

import { watch, emit, reactive } from 'vue';

export default {
  name: "Radio",
  components: {},

  props: {
    question: {
      type: String,
      default: "",
      required: true
    },

    choices: {
      type: Array,
      default: () => { [] },
      required: true
    },

    optional: {
      type: Boolean,
      default: false,
      required: true
    },

    category: {
      type: String,
      default: "",
      required: false
    }
  },

  emits: ["valueUpdated"],

  setup(props, { emit }) {
    const obj = reactive({
      value: "",
    });

    watch(() => obj.value, (newVal) => {
      emit("valueUpdated", { newVal });
    });

    watch(() => props.loaded, () => {
      obj.value = null;
    });

    return { props, obj }
  },

  QuestionText() {
    return this.question;
  },

  IsWellbeing() {
    return this.category === "Wellbeing";
  },
}
</script>
<style lang="scss">
form {
  max-width: 250px;
  position: relative;
  margin: 50px auto 0;
  font-size: 1rem;
  line-height: 40px;
}

.question {
  color: #fff;
  font-size: 2rem;
  text-align: center;
  margin: 8px auto 64px auto;

}

.radiobtn {
  position: relative;
  display: block;
}

.radiobtn label {
  display: block;
  background-color: #FFE7E7;
  color: #4E4B48;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 1rem;
  padding: 16px 32px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.radiobtn label:hover {
  transform: scale(0.95);
  transition: 0.2s;
}

.radiobtn label:active {
  transform: scale(0.9);
  transition: 0.2s;
}

.radiobtn label:after,
.radiobtn label:before {
  content: "";
  position: absolute;
  right: 11px;
  top: 11px;
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 50%;
  background: transparent;
}

.radiobtn label:before {
  background: transparent;
  -webkit-transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
    0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
  z-index: 2;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 13px;
  background-position: center;
  width: 0;
  height: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNS4zIDEzLjIiPiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE0LjcuOGwtLjQtLjRhMS43IDEuNyAwIDAgMC0yLjMuMUw1LjIgOC4yIDMgNi40YTEuNyAxLjcgMCAwIDAtMi4zLjFMLjQgN2ExLjcgMS43IDAgMCAwIC4xIDIuM2wzLjggMy41YTEuNyAxLjcgMCAwIDAgMi40LS4xTDE1IDMuMWExLjcgMS43IDAgMCAwLS4yLTIuM3oiIGRhdGEtbmFtZT0iUGZhZCA0Ii8+PC9zdmc+);
}

.radiobtn input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radiobtn input[type="radio"]:checked+label {
  background: #00C7B1;
  -webkit-animation-name: blink;
  animation-name: blink;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  color: #fff;
  border-color: #00C7B1;
}

.radiobtn input[type="radio"]:checked+label:after {
  background: transparent;
}

.radiobtn input[type="radio"]:checked+label:before {
  width: 35px;
  height: 35px;
}

.skip {
  position: relative;
  float: left;
}

.skip label {
  display: block;
  background-color: transparent;
  color: #622398;
  border-radius: 10px;
  padding: 11px 20px;
  margin: 0 auto 5px auto;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  border: 1px solid transparent;
}

.skip label:hover {
  transform: scale(0.98);
  transition: 0.2s;
}

.skip label:active {
  transform: scale(0.95);
  transition: 0.2s;
}

.skip input[type="radio"] {
  display: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.skip input[type="radio"]:checked+label {
  background: transparent;
  border: 1px solid #ff578a;
  color: #ff578a;
}

.survey-body {
  width: 100%;
}
</style>
