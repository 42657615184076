<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
collapse-ref="/report/" nav-text="Dashboard" :class="getRoute() === 'report' ? 'active' : ''"
          :collapse="false">
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
collapse-ref="/pathways" nav-text="Recommendations"
          :class="getRoute() === 'pathways' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-chart-bar-32 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
collapse-ref="/profileinfo" nav-text="Profile Info"
          :class="getRoute() === 'profileinfo' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-single-02 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="obj.ul == 2" class="nav-item">
        <sidenav-collapse
collapse-ref="" nav-text="Admin" :class="getRoute() === 'admininfo' ? 'active' : ''"
          :collapse="true" class="show">
          <template #icon>
            <i class="ni ni-archive-2 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'Admin Info' }" mini-icon="AI" text="Admin Info" />
              <sidenav-item :to="{ name: 'Objectives' }" mini-icon="O" text="Objectives" />
              <sidenav-item :to="{ name: 'Interventions' }" mini-icon="I" text="Interventions" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li v-if="obj.ul == 2" class="nav-item">
        <sidenav-collapse collapse-ref="/objectives" nav-text="Objectives"
          :class="getRoute() === 'objectives' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-archive-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="obj.ul == 2" class="nav-item">
        <sidenav-collapse collapse-ref="/interventions" nav-text="Interventions"
          :class="getRoute() === 'interventions' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-archive-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li> -->
      <li v-if="obj.ul == 2 || obj.ul == 1" class="nav-item">
        <sidenav-collapse
collapse-ref="/companyreport" nav-text="Company Report"
          :class="getRoute() === 'companyreport' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-book-bookmark text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="obj.ul == 2 || obj.ul == 1" class="nav-item">
        <sidenav-collapse
collapse-ref="/managerreport" nav-text="Manager Report"
          :class="getRoute() === 'managerreport' ? 'active' : ''" :collapse="false">
          <template #icon>
            <i class="ni ni-briefcase-24 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>


      <li class="nav-item">
        <sidenav-collapse
collapse-ref="/support" nav-text="Support" :class="getRoute() === 'support' ? 'active' : ''"
          :collapse="false">
          <template #icon>
            <i class="ni ni-chat-round text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>



    <li class="nav-item">
      <sidenav-collapse
collapse-ref="/feedback" nav-text="Feedback" :class="getRoute() === 'feedback' ? 'active' : ''"
          :collapse="false">
      <template #icon>
        <i class="ni ni-notification-70 text-primary text-sm opacity-10 "></i>
      </template>
    </sidenav-collapse>
  </li>

    <li class="nav-item">
    <sidenav-collapse collapse-ref="/" nav-text="Logout" :collapse="false">
      <template #icon>
        <i class="ni ni-button-power text-primary text-sm opacity-10 "></i>
      </template>
    </sidenav-collapse>
  </li>

</ul>
  </div>
  <div class="mt-3 sidenav-footer">
    <!-- <sidenav-card
:card="{
      title: 'Need Help?',
      description: 'Please check our docs',
      links: [],
    }" /> -->
  </div>
</template>
<script>

import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavItem from "./SidenavItem.vue";
// import SidenavCard from "./SidenavCard.vue";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { reactive } from 'vue';

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    // SidenavCard,
    // SidenavCollapseItem,

  },
  setup() {
    const obj = reactive({
      ul: cookies.get("ul"),
    });

    return { obj }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    logout() {
      console.log();
    }
  },
};
</script>
