/**
=========================================================
* Vue Argon Dashboard 2 PRO - v3.0.1
=========================================================

* Product Page: https://creative-tim.com/product/vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import ArgonDashboard from "./argon-dashboard";
import VueCookies from "vue3-cookies";
import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyDDPHA11hWvHrDlj9AmkbLlNB7d8lxXe_s",
  authDomain: "lumien.firebaseapp.com",
  projectId: "lumien",
  storageBucket: "lumien.appspot.com",
  messagingSenderId: "94195584843",
  appId: "1:94195584843:web:09a2020be0717bfd6a62ce",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(VueCookies);
appInstance.mount("#app");
