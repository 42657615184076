export class URLLocations {
  location = "staging";

  reportGeneratorURL = "";
  surveyprocessorURL = "";

  public constructor() {
    switch (this.location) {
      case "local":
        this.reportGeneratorURL = "http://localhost:7073";
        this.surveyprocessorURL = "http://localhost:7072";
        break;
      case "staging":
        this.reportGeneratorURL =
          "https://lumien-personal-reportgenerator-function-dev.azurewebsites.net";
        this.surveyprocessorURL =
          "https://lumien-personal-surveyprocessor-function-dev.azurewebsites.net";
        break;
      case "beta":
        this.reportGeneratorURL = "";
        this.surveyprocessorURL =
          "https://lumien-personal-surveyprocessor-function-dev.azurewebsites.net";
        break;
      case "live":
        this.reportGeneratorURL = "";
        this.surveyprocessorURL =
          "https://lumien-personal-surveyprocessor-function-dev.azurewebsites.net";
        break;
      default:
        this.reportGeneratorURL = "http://localhost:7073";
        this.surveyprocessorURL = "http://localhost:7072";
        break;
    }
  }
}
