import { ApiError } from "./models/apierror";
import { User } from "./models/apiresponse";

import { UserDetailsResponse } from "./models/userDetailsResponse";
import { UserLevelDTO } from "./models/userLevelDTO";
import { URLLocations } from "./models/urlLocations";
import { MissionVisionCollectionDTO } from "./models/missionVisionCollectionDTO";
import { ObjectivesDTO } from "./models/objectivesDTO";
import { InterventionsDTO } from "./models/interventionsDTO";

const urlLocations = new URLLocations();
const reportGeneratorURL = urlLocations.reportGeneratorURL;
const surveyprocessorURL = urlLocations.surveyprocessorURL;

export class UserService {
  public static login(
    emailAddress: string,
    password: string,
    reportId: string
  ): Promise<User> {
    const reportUrl = reportGeneratorURL + "/api/v2/user/Login/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EmailAddress: emailAddress,
        OrganisationId: 1,
        Password: password,
        ReportId: reportId,
      }),
    })
      .then((response) => {
        return response.json() as Promise<User>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static login_v2(
    emailAddress: string,
    password: string,
    reportId: string
  ): Promise<User> {
    const reportUrl = reportGeneratorURL + "/api/v2/user/Login_v2/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EmailAddress: emailAddress,
        OrganisationId: 1,
        Password: password,
        ReportId: reportId,
      }),
    })
      .then((response) => {
        return response.json() as Promise<User>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static changePassword(
    eemailAddress: string,
    newpassword: string
  ): Promise<User> {
    const reportUrl = surveyprocessorURL + "/api/v1/changePassword/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EEmailAddress: eemailAddress,
        NewPassword: newpassword,
      }),
    })
      .then((response) => {
        return response.json() as Promise<User>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static forgotPassword(emailAddress: string): Promise<boolean> {
    const reportUrl = surveyprocessorURL + "/api/v1/forgotPassword/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EmailAddress: emailAddress,
      }),
    })
      .then(() => {
        try {
          return true;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getUserDetails(
    emailAddress: string
  ): Promise<UserDetailsResponse> {
    const reportUrl = surveyprocessorURL + "/api/v2/user/getUserDetails/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EmailAddress: emailAddress,
      }),
    })
      .then((data) => {
        try {
          return data.json() as Promise<UserDetailsResponse>;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static setUserDetails(userDetailsResponse: UserDetailsResponse) {
    const reportUrl = surveyprocessorURL + "/api/v2/user/setUserDetails/";
    console.log(userDetailsResponse.dateOfBirth);
    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        FirstName: userDetailsResponse.firstName,
        LastName: userDetailsResponse.lastName,
        Email: userDetailsResponse.email,
        Gender: userDetailsResponse.gender,
        Ethnicity: userDetailsResponse.ethnicity,
        Sexuality: userDetailsResponse.sexuality,
        OpenSexuality: userDetailsResponse.openSexuality,
        Religion: userDetailsResponse.religion,
        PracticingReligion: userDetailsResponse.practicingReligion,
        DateOfBirth: userDetailsResponse.dateOfBirth,
        Postcode: userDetailsResponse.postcode,
        RelationshipStatus: userDetailsResponse.relationshipStatus,
        Dependants: userDetailsResponse.dependants,
        CompanyName: userDetailsResponse.companyName,
        TeamName: userDetailsResponse.teamName,
        Salary: userDetailsResponse.salary,
        Education: userDetailsResponse.education,
        JobTitle: userDetailsResponse.jobTitle,
        Experience: userDetailsResponse.experience,
        Remote: userDetailsResponse.remote,
      }),
    })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getUserLevels(
    organisationId: number,
    requesterUserLevel: number
  ): Promise<UserLevelDTO[]> {
    const reportUrl = surveyprocessorURL + "/api/v1/userlevel/GetUserLevels/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        OrganisationId: organisationId,
        UserLevel: requesterUserLevel,
      }),
    })
      .then((response) => {
        return response.json() as Promise<UserLevelDTO[]>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static setUserLevel(userLevels: UserLevelDTO[]) {
    const reportUrl = surveyprocessorURL + "/api/v1/userlevel/SetUserLevel/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify(userLevels),
    })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getMissionVisionCollection(
    organisationId: number
  ): Promise<MissionVisionCollectionDTO> {
    const reportUrl =
      surveyprocessorURL +
      "/api/v1/UpdateOrganisation/GetMissionVisionCollection/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        OrganisationId: organisationId,
      }),
    })
      .then((response) => {
        return response.json() as Promise<MissionVisionCollectionDTO>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static setMissionVisionCollection(
    missionVisionCollectionDTO: MissionVisionCollectionDTO
  ) {
    const reportUrl =
      surveyprocessorURL +
      "/api/v1/UpdateOrganisation/SetMissionVisionCollection/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify(missionVisionCollectionDTO),
    })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getOrgReportIdString(
    organisationId: number
  ): Promise<MissionVisionCollectionDTO> {
    const reportUrl = `${surveyprocessorURL}/api/v1/UpdateOrganisation/GetOrgReportIdString/` + organisationId;

    const missionVisionCollectionDTO = new MissionVisionCollectionDTO();
    missionVisionCollectionDTO.organisationId = organisationId;    

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((data) => {
        try {
          return data.json() as Promise<MissionVisionCollectionDTO>;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getObjectives(organisationId: number): Promise<ObjectivesDTO> {
    const reportUrl =
      surveyprocessorURL +
      "/api/v1/Objectives/GetObjectivesByOrg/" +
      organisationId;

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<ObjectivesDTO>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static setObjectives(objectivesDTO: ObjectivesDTO) {
    const reportUrl = surveyprocessorURL + "/api/v1/Objectives/AddObjectives/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify(objectivesDTO),
    })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getInterventions(organisationId: number): Promise<InterventionsDTO> {
    const reportUrl =
      surveyprocessorURL +
      "/api/v1/Interventions/GetInterventionsByOrg/" +
      organisationId;

    return fetch(reportUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<InterventionsDTO>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static setInterventions(interventionsDTO: InterventionsDTO) {
    const reportUrl = surveyprocessorURL + "/api/v1/Interventions/AddInterventions/";

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify(interventionsDTO),
    })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError("Error", "Something went wrong, please try again");
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }
}
