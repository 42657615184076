<template>
  <div v-if="!obj.loaded">
    Loading mission vision collection, please wait....
  </div>
  <div v-if="obj.loaded" id="profile" class="card card-body mt-4 profile name-info">
    <div class="row ">

      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">About your organisations culture</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Purpose</label>
          <a href="learn more" class="learn-more">learn more</a>
          <textarea
id="companyPurpose" v-model="obj.missionVisionCollection.companyPurpose" class="form-control"
            type="text" placeholder="Click here to enter your company purpose" />
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Vision</label>
          <a href="learn more" class="learn-more">learn more</a>
          <textarea
id="companyVision" v-model="obj.missionVisionCollection.companyVision" class="form-control"
            type="text" placeholder="Click here to enter your company vision" />
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Mission</label>
          <a href="learn more" class="learn-more">learn more</a>
          <textarea
id="companyMission" v-model="obj.missionVisionCollection.companyMission" class="form-control"
            type="text" placeholder="Click here to enter your company mission"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Company Values</label>
          <a href="learn more" class="learn-more">learn more</a>
          <div v-for="cValueRow in obj.companyValues" :key="cValueRow">
            <textarea
v-model="cValueRow.cValue" class="form-control" type="text"
              placeholder="Click here to enter a company value"></textarea>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <argon-button class="float-end mt-6 mb-0 value-btn" color="dark" variant="gradient" size="md" @click="AddValueBox()">Add another
            company value</argon-button>
        </div>
      </div>


      <div class="row">
        <div class="col-6">
          <argon-button
class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md"
            @click="SaveAnswers()">Save</argon-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { UserService } from '@/api/userservice';
import { MissionVisionCollectionDTO } from '@/api/models/missionVisionCollectionDTO';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";
const { cookies } = useCookies();

export default {
  name: "SideNavItem",
  components: {
    ArgonButton
  },

  props: {},
  setup(props) {
    const obj = reactive({
      missionVisionCollection: MissionVisionCollectionDTO,
      companyValues: [{}],
      loaded: false,
    });

    var user = cookies.get("lumienUser");

    onMounted(() => {
      UserService.getMissionVisionCollection(user.organisationId).then(response => {

        obj.missionVisionCollection = response;

        if (obj.missionVisionCollection.companyValues == null) obj.companyValues = [{ cValue: "" }];
        else obj.companyValues = JSON.parse(obj.missionVisionCollection.companyValues);

        obj.loaded = true;
      });
    });

    return { props, obj };
  },



  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {

    SaveAnswers() {

      this.obj.missionVisionCollection.companyValues = JSON.stringify(this.obj.companyValues);
      UserService.setMissionVisionCollection(this.obj.missionVisionCollection).then(() => {
        this.$swal({
          title: "Your changes have been saved!",
        });
      });
    },

    AddValueBox() {
      this.obj.companyValues.push({ cValue: "" });
    }
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}

textarea.form-control {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  transition: 0.2s;
  margin: 0px 0px 8px 0px;
}

textarea.form-control:focus,
textarea.form-control:hover {
  width: 100%;
  height: 80px;
  resize: vertical;
  min-height: 60px;
  background-color: #fff;
  border: 1px solid #d2d6da;
  box-shadow: none;
  transition: 0.2s;
}

.form-label {
  font-size: 0.875rem;
  color: #4E4B48;
  font-weight: 600;
  margin: 8px 0px 0px 0px;
}

.learn-more {
  font-size: 0.75rem;
  text-decoration: underline;
  color: #E63888;
  margin: 8px 0px 0px 0px;
  float: right;
}

.value-btn{
  width:100%;
  font-size:0.75rem;
  max-width:100%;
  border-radius:8px;
  margin:0px;
  margin-top: 8px !important;
  background-color:#02B298 !important;
}


</style>
