<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="card dom-score mt-4">
    <div v-if="!loaded">
      Loading, please wait....
    </div>
    <div class="graphs">
      <div v-if="loaded">


        <div class="row scores">


          <div class="measure-score col-md-3">
            <svg class="circle-chart" :class="{ disabled: noScore, stale: staleScore }"
              viewBox="0 0 33.83098862 33.83098862" width="100%" max-width="100px" xmlns="http://www.w3.org/2000/svg">
              <circle class="circle-chart__background" stroke="rgba(95, 36, 159, 0.25)" stroke-width="3" fill="none"
                cx="50%" cy="50%" r="45%" />
              <circle class="circle-chart__circle" stroke="#5F249F" stroke-width="3"
                :stroke-dasharray="percentageFormat" stroke-linecap="round" fill="none" cx="50%" cy="50%" r="45%" />
              <text class="circle-chart__percent" x="50%" y="50%" alignment-baseline="central" text-anchor="middle"
                dominant-baseline="middle" font-size="8" fill="#5F249F">
                {{ scoreLabel }}
              </text>
            </svg>
            <span class="stale-label">
              {{ staleLabel }}
            </span>
          </div>


          <div v-if="scoreLabel != 'N/A'" class="col-md-9">
            <LineChart :chart-data="chartData" :loaded="true"></LineChart>
          </div>
        </div>
      </div>

      <h3>{{ title }}</h3>
      <div class="domdesc">
        {{ description }}
      </div>
      <hr />
      <div class="recommendations" @click="showAlert">
        <strong class="number-highlight"> {{ recommendations.length }}</strong> Recommendations.
      </div>

      <hr />
      <div v-if="subdomains" class="subdomains">
        <a :href="subdomainLink">
          <strong class="number-highlight">{{ subdomains.length }}</strong> Subdomains.
        </a>
        <!-- <div v-for="subdomain in subdomains" :key="subdomain.title" class="card mt-4">
          {{ subdomain.title }}
        </div> -->
      </div>
      <hr />
    </div>
  </div>

  <div class="hiddenHtml" style="display:none;" :class="className">
    <div v-if="loaded">
      <div class="rectitle">
        <h2>Your {{ title }} Recommendations</h2>
      </div>
    </div>
    <div v-for="recommendation in recommendations" :key="recommendation.title" class="card mt-4">
      <a :href="recommendation.link" target="_blank">
        <div class="card-body recommendationsCard">
          <div class="row">
            <div class="col-md-3">
              <img class="col-md-12" :src="recommendation.image" />
            </div>
            <div class="col-md-9 rectitle">
              <h5>{{ recommendation.title }}</h5>
              <i class="fas recicon" :class="recommendation.typeIcon"></i>
              <div class="rectext" v-html="recommendation.description"></div>
            </div>
            <div class="col-md-1">

            </div>
          </div>
        </div>
      </a>
    </div>
  </div>




</template>

<script>
import moment from 'moment';
import LineChart from './LineChart.vue'
import { computed, reactive, ref } from 'vue';

export default {
  name: "DomainCard",
  components: {
    LineChart
  },

  props: {
    score: Number,
    scoreDate: String,
    chartData: [],
    loaded: Boolean,
    title: String,
    description: String,
    recommendations: [],
    subdomains: [],
    reportId: String,
  },
  setup(props) {
    const scoreLabel = computed(() => {
      if (!(props.score == null || props.score === undefined))
        return props.score.toString();
      else return "N/A";
    });

    const percentageFormat = computed(() => {
      return props.score + " 100";
    });


    const noScore = computed(() => {
      return props.score == null || props.score === undefined;
    });

    const staleScore = computed(() => {
      return props.scoreDate != null && props.scoreDate !== undefined;
    });

    const staleLabel = computed(() => {
      const scoreDate2 = moment(props.scoreDate);
      if (staleScore.value) return "Last asked " + scoreDate2.format("DD MMM YY");
      else if (props.score === null) return "No data";
      else return "";
    });

    const subdomainLink = computed(() => {
      return "/report/subdomain/" + props.reportId + "/" + props.title;
    });

    const className = computed(() => {
      return props.title.replace(/\s/g, "");
    })

    return {
      scoreLabel, percentageFormat, noScore, staleScore, staleLabel, props, className, subdomainLink
    }
  },
  methods: {
    showAlert() {

      let templateHtml = document.body.querySelector('.hiddenHtml' + '.' + this.props.title.replace(/\s/g, "")).innerHTML;
      console.log(templateHtml);
      // Use sweetalert2
      this.$swal({
        title: "",
        toast: false,
        html: templateHtml,
        width: 600,
      });
    }
  }

};
</script>



<style lang="scss" scoped>
.measure-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 150px;
  padding: 0px !important;
  vertical-align: middle;
}

.dom-score {
  background-color: #f7f5f3;
  border: 1px solid #FFE7E7;
  color: #4E4B48;
  text-align: left;
  box-sizing: boder-box;
  padding: 32px;
}


h3 {
  color: #4E4B48;
  font-size: 1.25rem;
}

text {
  fill: #622398;
}

.info {
  color: #622398;
  float: right;
}

.circle-chart__percent {
  color: #5F249F;
}

svg {
  max-width: 200px;
}

.stale {
  filter: opacity(0.7);
}

.stale-label {
  width: 100%;
  font-size: 0.5rem;
  font-weight: 400;
  text-align: center;
  margin: 16px 0;
  font-style: italic;
}

.disabled {
  filter: opacity(0.5);
}

.locked {
  background-color: #abafb3;
  color: #e1e5ea;
  border-color: #abafb3;
}

.score {
  display: inline-block;
  width: 50%;
  text-align: center;
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
  /* 1 */
  transform: rotate(-90deg);
  /* 2, 3 */
  transform-origin: center;
  /* 4 */
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.5em);
}

.circle-chart__percent {
  font-weight: 800;
  color: #4E4B48;
}

.rectitle h2 {
  font-size: 1.75rem;
  text-align: left;
  color: #5F249F;
}

h5 {
  margin-bottom: 0px !important;
}

img {
  border-radius: 8px;

}

.recommendationsCard {
  padding: 16px !important;
  transition: 0.2s;
  text-align: left;
}

.recommendationsCard:hover {
  transform: scale(0.98);
  transition: 0.2s;
}

.recommendationsCard:active {
  transform: scale(0.95);
  transition: 0.2s;
}

.rectitle {
  padding: 0px;
  margin: 8px 0 0 0;
}

.domdesc {
  font-size: 0.875rem;
  margin: 16px 0;
}

.recommendations,
.subdomains {
  font-weight: 600;
  font-size: 0.875rem;
  transition: 0.2s;

}

.recommendations:hover,
.subdomains:hover {
  color: #E63888;
  cursor: pointer;
  transform: scale(1.05);
  transition: 0.2s;
}

.recommendations:hover::after,
.subdomains:hover::after {
  content: ">";
  font-size: 1.25rem;
  line-height: 0;
  text-align: right;
  float: right;
  vertical-align: middle;
  padding: 10px;
  transition: 0.2s;
}

.recommendations:hover .number-highlight,
.subdomains:hover .number-highlight {
  background-color: #E63888;
  transition: 0.2s;
}

.scores {
  margin: auto auto 32px auto;
}
</style>
