import { Organisation } from "./organisation";

export class Choice {
  text = "";
}

export class QuestionDefinition {
  id = "";
  type: string | null = null;
  text: string | null = null;
  choices: Choice[] = [];
  category = "";
  optional = true;
  questionOrder = 0;
}

export class SurveyDefinition {
  organisation: Organisation | null = null;
  Title: string | null = null;
  Questions: QuestionDefinition[] = [];
}
