<template>

<div class="py-4 container-fluid">
    <div class="row">
  <div class="card content">
    <div class="col-md-12">
    <h5>Crisis Support
    </h5>
    <br /><br />

    <strong>If you are having thoughts about ending your life then call 999 or go to A&amp;E and ask for your nearest Crisis Resolution Team.</strong>
  <p>These are teams of health care professionals that support individuals in severe distress.</p>
  <p>For immediate support 24/7 you can contact</p>
  
  <a href="https://www.samaritans.org/how-we-can-help/contact-samaritan/"><strong>The Samaritans</strong></a><br />
    Call 116 123 (UK)<br />
    or email<a href="mailto:jo@samaritans.org.uk"> jo@samaritans.org.uk</a>
    <br /><br />
  <strong><a href="https://www.crisistextline.uk/">Shout</a></strong><br />
    Text Shout to 85258 (UK)
  


</div>
</div></div>
  </div>
</template>

<script>

export default {
  name: "CrisisSupport",
  components: {},
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>

.content{
  padding:32px;
  background-color: #F7F5F3;
color: #4E4B48;
width:70%;
min-width:700px;
}
@media only screen and (max-width: 1200px) {
  .content{
width:100%;
min-width:auto;
}
      }
</style>