<template>
  <div class="card position-sticky top-1 mt-4">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#profile">
          <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
          <span class="text-sm">Profile Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#basic-info">
          <i class="ni ni-books me-2 text-dark opacity-6"></i>
          <span class="text-sm">Basic Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#changepassword">
          <i class="ni ni-lock-circle-open me-2 text-dark opacity-6"></i>
          <span class="text-sm">Change Password</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#relationships">
          <i class="ni ni-atom me-2 text-dark opacity-6"></i>
          <span class="text-sm">Relationships</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#workandeducation">
          <i class="ni ni-ui-04 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Work and Education</span>
        </a>
      </li>
      <!-- <li class="nav-item pt-2">
        <a
          class="nav-link text-body d-flex align-items-center"
          data-scroll
          href="#health"
        >
          <i class="ni ni-badge me-2 text-dark opacity-6"></i>
          <span class="text-sm">Health</span>
        </a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "SideNav",
};
</script>
