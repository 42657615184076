<template>
  <div v-if="props.loaded" class="card trans-card recommendations-widget">
    <h3 class="text-white">Your Recommendations</h3>
    <div class="row">
      <div class="col-md-12">
        <button @click="filterRecommendations('')">Show All ({{ obj.numberOfAll }})</button>

        <button :disabled="obj.numberOfPathways == 0" @click="filterRecommendations('Pathway')">Pathways ({{
          obj.numberOfPathways }})</button>
        <button :disabled="obj.numberOfArticles == 0" @click="filterRecommendations('Article')">Articles ({{
          obj.numberOfArticles }})</button>
        <button :disabled="obj.numberOfBenefits == 0" @click="filterRecommendations('Benefit')">Company Benefits ({{
          obj.numberOfBenefits }})</button>
        <button :disabled="obj.numberOfActions == 0" @click="filterRecommendations('InlineAction')">Quick
          Actions ({{
            obj.numberOfActions }})</button>
        <button :disabled="obj.numberOfLinks == 0" @click="filterRecommendations('Link')">External
          Links ({{
            obj.numberOfLinks }})</button>

        <button :disabled="obj.numberOfSupports == 0" @click="filterRecommendations('Link')">Support
          ({{
            obj.numberOfSupports }})</button>
      </div>

    </div>
    <div class="hiddenList">
      <div v-for="recommendation in obj.filteredRecs" :key="recommendation.title" class="card mt-2 recommendationsCard" style="cursor: pointer;">
        <a
v-if="recommendation.recommendationType != 'InlineAction' && recommendation.recommendationType != 'Benefit'"
          :href="recommendation.link" target="_blank">
          <div class="card-body ">
            <div class="row">
              <div class="col-md-3">
                <img class="col-md-12" :src="recommendation.image" />
              </div>
              <div class="rectitle col-md-9">
                <h5>{{ recommendation.title }}</h5>
                <i class="fas recicon" :class="recommendation.typeIcon"></i>
                <div class="rectext" v-html="recommendation.description"></div>

              </div>
              <div class="col-md-1">
                <a target="_blank">

                  <div class="tooltipwrapper">
                    <span class="tooltiptext">{{ recommendation.toolTip }}</span> 
                  </div>
                </a>
              </div>
            </div>
          </div>
        </a>

        <a v-if="recommendation.recommendationType == 'InlineAction'"
          :href="recommendation.link" target="_blank" @click="openPopup(recommendation)" >
          <div class="card-body ">
            <div class="row">              
              <div class="rectitle col-md-12">
                <h5>{{ recommendation.title }}</h5>
                <i class="fas recicon" :class="recommendation.typeIcon"></i>
                <!-- <div class="rectext" v-html="recommendation.description"></div> -->

              </div>
              <div class="col-md-1">
                <a target="_blank">

                  <div class="tooltipwrapper">
                    <span class="tooltiptext">{{ recommendation.toolTip }}</span> 
                  </div>
                </a>
              </div>
              <div class="col-md-1">
                <a target="_blank">
                  <div class="tooltipwrapper">
                    <span class="tooltiptext">{{ recommendation.toolTip }}</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </a>

        <a
v-if="recommendation.recommendationType == 'Benefit'"
          :href="recommendation.link" target="_blank" @click="openPopup(recommendation)" >
          <div class="card-body ">
            <div class="row">        
              <div class="col-md-3">
                <img class="col-md-12" :src="recommendation.image" />
              </div>      
              <div class="rectitle col-md-9">
                <h5>{{ recommendation.title }}</h5>
                <i class="fas recicon" :class="recommendation.typeIcon"></i>
                <!-- <div class="rectext" v-html="recommendation.description"></div> -->

              </div>
              <div class="col-md-1">
                <a target="_blank">
                  <div class="tooltipwrapper">
                    <span class="tooltiptext">{{ recommendation.toolTip }}</span>
                  </div>

                </a>
              </div>
            </div>
          </div>
        </a>



      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch, onMounted, ref } from 'vue';

export default {
  name: "RecommendationsCard",
  components: {},
  props: {
    recommendations: [],
    numberOfRecs: Number,
    loaded: Boolean,
  },
  setup(props) {
    const obj = reactive({
      filteredRecs: props.recommendations,
      currentFilter: "",
      numberOfPathways: 0,
      numberOfArticles: 0,
      numberOfBenefits: 0,
      numberOfActions: 0,
      numberOfLinks: 0,
      numberOfSupports: 0,
      numberOfAll: 0,
      numberOfRecs: 0,
      unfilteredRecs: props.recommendations,

      numberOfColumns: "col-md-9",
    });



    watch(() => props.loaded, () => {

      obj.filteredRecs = ref(props.recommendations);
      obj.unfilteredRecs = obj.filteredRecs;
      obj.numberOfRecs = ref(props.numberOfRecs);

      console.log(obj.numberOfRecs);

      if (obj.filteredRecs) {
        obj.currentFilter = "";

        obj.numberOfAll = obj.filteredRecs.length;
        obj.numberOfPathways = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Pathway'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfArticles = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Article'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfBenefits = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Benefit'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfActions = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'InlineAction'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfLinks = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Links'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfSupports = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Support'; }).length
        obj.filteredRecs = ref(props.recommendations);
        obj.filteredRecs = obj.filteredRecs.slice(0, obj.numberOfRecs);

      } else {
        console.log("no Recommendations at this time");
      }

    });

    onMounted(() => {

      obj.filteredRecs = ref(props.recommendations);
      obj.unfilteredRecs = obj.filteredRecs;
      obj.numberOfRecs = ref(props.numberOfRecs);



      if (obj.filteredRecs) {
        obj.currentFilter = "";
        console.log(props.recommendations);
        obj.numberOfAll = obj.filteredRecs.length;
        obj.numberOfPathways = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Pathway'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfArticles = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Article'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfBenefits = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Benefit'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfActions = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'InlineAction'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfLinks = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Link'; }).length
        obj.filteredRecs = ref(props.recommendations);

        obj.numberOfSupports = obj.filteredRecs.filter(function (element) { return element.recommendationType == 'Support'; }).length
        obj.filteredRecs = ref(props.recommendations);
        obj.filteredRecs = obj.filteredRecs.slice(0, obj.numberOfRecs);

      } else {
        console.log("no Recommendations at this time");
      }

    });



    return { obj, props }
  },



  methods: {
    filterRecommendations(filter) {
      this.obj.currentFilter = filter;

      if (this.obj.currentFilter == "") {
        this.obj.filteredRecs = this.obj.unfilteredRecs.slice(0, this.obj.numberOfRecs);
      } else {
        this.obj.filteredRecs = this.obj.unfilteredRecs.filter(this.recFilter).slice(0, this.obj.numberOfRecs);
      }
    },

    recFilter(element, index, array) {
      return (element.recommendationType == this.obj.currentFilter)
    },

    openPopup(recommendation) {

      var templateHtml = "<div>" +
        recommendation.description
        + "</div>"


      this.$swal({
        title: recommendation.title,
        toast: false,
        html: templateHtml,
        width: 600,
      });
    }
  }
};
</script>

<style scoped>
.recommendations-widget button {
  font-size: 0.6rem;
  font-weight: 600;
  display: inline-block;
  float: left;
  margin: 4px;
  padding: 6px 8px;
  border: 1px solid #fff;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  border-radius: 8px;
  background-color: #5F249F;
  color: #fff;
}

.recommendations-widget button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.recommendations-widget button:hover,
.recommendations-widget button:active,
.recommendations-widget button:focus {
  background-color: #02B298;
  color: #fff;
}

span {
  color: #4E4B48;
  font-size: 0.75rem;
  margin: 0px auto;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltiptext:hover {
  visibility: visible;
}

.tooltiptext {
  visibility: hidden;
  border-radius: 6px;
  z-index: 1;
  top: -3rem;
  transform: translate(-100%, -35%);
  -webkit-transform: translate(-100%, -35%);
  color: #595959;
  background-color: #fff;
  text-align: left;
  padding: 15px;
  position: absolute;
  font-weight: 100;
  font-size: 1rem;
  line-height: 120%;
  min-width: 8rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(98, 35, 152, 0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(98, 35, 152, 0.25);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.hiddenList {
  padding: 10px;
  flex-wrap: wrap;

}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  border: none;
}

img {
  border-radius: 8px;

}

.recommendationsCard {
  padding: 16px !important;
  transition: 0.2s;
}

.recommendationsCard:hover {
  transform: scale(0.98);
  transition: 0.2s;
}

.recommendationsCard:active {
  transform: scale(0.95);
  transition: 0.2s;
}

.rectitle {
  margin: 8px 0 0 0;
}

h5 {
  margin-bottom: 0px !important;
}

.recicon {
  font-size: 0.5rem !important;
  margin: 0px;
  padding: 0px;
  font-weight: 100;
  color: #4E4B48;
}

.card-body {
  padding: 0px !important;
}
</style>
