<template>

  <p></p>
</template>

<script>
export default {
  name: "SurveyQuestionHeader",
  components: {},
}
</script>

<style scoped>

</style>