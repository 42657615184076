<template>
  <div class="card bg-secondary h-100">
    <div class="card-body coming-soon">
      <div class="row justify-content-between align-items-center">
        <h3 class="text-white">do you want to check in today?</h3>
      </div>
      <div class="my-4">
        <div
class="h6 text-white cursor-pointer" data-bs-toggle="tooltip" data-bs-placement="bottom"
          title="check in now to update your scores & recommendations">
          check in now to update your scores & recommendations
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <argon-button full-width="false" color="warning" variant="contained" disabled>
            coming soon
          </argon-button>

        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "CheckInCard",
  components: {
    ArgonButton,
  },
};
</script>
<style scoped>

.btn-warning:disabled, .btn-warning.disabled{
  color:#fff;
  border: 2px solid #fff;
  background-color:#E63888;
  opacity:1;
 width:250px !important;
  border-radius:16px;
  font-size:0.875rem;
}
</style>