<template>
  <section id="thankyoupage">
    <div class="center">
      <i class="fa fa-thumbs-up large-icon jello-horizontal"></i>
      <h2>Thank you!</h2>
      That's all your questions for now. Your report will be with you shortly.
      Please check your junk mail if you haven't recieved it within 24 hours.
      <br />
    </div>
  </section>
</template>
<script>


import { reactive } from 'vue';
export default {
  name: "SurveyThankYou",
  components: {},

  setup() {
    const obj = reactive({
      fbShareUrl: "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.lumien.io%2F&amp;src=sdkpreparse",
    });

    return { obj };
  },

}
</script>

<style lang="scss">
#thankyoupage {
  padding-top: 40px;
}

.social {
  margin: 30px auto;
  padding: 15px 30px;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid #f2f2f2;
  border-radius: 10px;
}

.twitter-share-button {
  margin-bottom: -10px;
}

.large-icon {
  font-size: 3rem;
}
</style>
