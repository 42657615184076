<template>

<div class="py-4 container-fluid">
    <div class="row">

    <div class="col-md-12">

  
    <iframe width="750px" height="2480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=4X30lU-YrU69pF4_j-DBRrkUyGwfwTNEkq4fQOyDZVhUQ1BKSDE3WTJJSEFJRjc5UkczTDdZUU00MC4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style="border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
  

</div></div>
  </div>
</template>

<script>

export default {
  name: "Feedback",
  components: {},
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>

.content{
  padding:32px;
  background-color: #F7F5F3;
color: #4E4B48;
width:70%;
min-width:700px;
}
@media only screen and (max-width: 1200px) {
  .content{
width:100%;
min-width:auto;
}
      }
</style>