import { Organisation } from "./organisation";

export class HistoryValue {
  date: string;
  value: number;

  get Date() {
    return new Date(this.date);
  }

  public constructor() {
    this.date = "";
    this.value = 0;
  }
}

export class Recommendation {
  link = null;
  title = "";
  description = "";
  image = "";
  recommendationType = "";
  typeIcon = "";
  toolTip = "";
}

export class User {
  email = "";
  firstName = "";
  lastName = "";
  hasPassword = false;
  reportId = "";
  JWT = "";
  isAutherized = false;
  userLevel = 0;
}

export class SubdomainResult {
  title = "";
  description = "";
  score = null;
  scoreDate = null;

  history = [];
  recommendations = [];

  locked = false;
}

export class DomainResult extends SubdomainResult {
  subdomains = [];
}

export class APIResponse {
  organisation: Organisation | null = null;
  genericLinks: Recommendation[] = [];
  reportDate: string | null = null;
  wellbeingScore: number | null = null;
  wellbeingScoreHistory: HistoryValue[] = [];
  domains: DomainResult[] = [];
  premium = false;
  hasPassword = "No";
}

export class dataSet {
  data: [];

  constructor() {
    this.data = [];
  }
}

export class chartData {
  labels: Array<string>;
  dataSets: Array<dataSet>;

  constructor(labels: Array<string>, dataSet: dataSet) {
    this.dataSets = [dataSet];
    this.labels = labels;
  }
}


