export class Organisation {
  name = "";
  logoUri: string;
  requireAccessCode = true;
  customCSS: string | null = null;
  companyId: string | null = null;

  public constructor(){
    this.logoUri = "";
    this.customCSS = "";
    this.companyId = "";
  }
}
