<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="py-4 container-fluid">
    <div v-if="!obj.loaded">
      Loading, please wait....
    </div>
    <div v-if="obj.loaded" class="row">
      <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md" @click="SaveAnswers()">Back to
        report</argon-button>
      <div v-for="domain in obj.domains" :key="domain.title" class="col-md-6">
        <domain-card :score="domain.score" :loaded="obj.loaded" :score-date="domain.scoreDate" :title="domain.title"
          :chart-data="domain.history" :description="domain.description" :recommendations="domain.recommendations"
          :subdomains="domain.subdomains"></domain-card>
      </div>
    </div>
  </div>
</template>

<script>

import DomainCard from "./components/DomainCard.vue";
import { ReportService } from '@/api/reportservice';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";
const { cookies } = useCookies();


export default {
  name: "Subdomain",
  components: {
    DomainCard,
    ArgonButton
  },
  props: {
    reportId: String,
    domainTitle: String,
  },

  setup(props) {
    const obj = reactive({
      loaded: false,
      chartData: [],
      chartOptions: {
        responsive: true
      },
      wellbeingScore: 0,
      wellbeingScoreDate: "",
      reportId: props.reportId,
      recommendations: [],
      domains: [],
      title: props.domainTitle
    });

    onMounted(() => {
      if (obj.reportId === "") {
        var user = cookies.get("lumienUser");
        obj.reportId = user.reportId;

        if (obj.reportId === "") {
          console.log("Need to find the report Id")
        }
      }


      ReportService.getSubdomains(obj.reportId, obj.title).then((data) => {
        obj.loaded = true;
        obj.domains = data.subdomains;
        console.log(obj.domains);
      });

    });

    return { obj, props }

  },

  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    SaveAnswers() {
      this.$router.push('/report');
    },
  },
};
</script>
<style scoped>
h2 {
  color: #fff;
}
</style>