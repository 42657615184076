import { createRouter, createWebHistory } from "vue-router";
import Report from "../views/report/Report.vue";
import Subdomain from "../views/subdomain/Subdomain.vue";
import Pathways from "../views/pathways/Pathways.vue";
import Survey from "../views/survey/Survey.vue";

import Login from "../views/auth/login/Login.vue";
import NewPassword from "../views/auth/login/NewPassword.vue";
import Register from "../views/auth/login/Register.vue";
import ForgotPassword from "../views/auth/login/ForgotPassword.vue";

import CrisisSupport from "../views/InfoPages/CrisisSupport.vue";
import Support from "../views/InfoPages/Support.vue";
import Feedback from "../views/InfoPages/Feedback.vue";

import ProfileInfo from "../views/ProfileInfo/ProfileInfo.vue";
import AdminInfo from "../views/AdminInfo/AdminInfo.vue";
import AdminInfoObjectiveMapping from "../views/AdminInfoObjectiveMapping/AdminInfoObjectiveMapping.vue";
import AdminInfoInterventionMapping from "../views/AdminInfoInterventionMapping/AdminInfoInterventionMapping.vue";
import ManagerReport from "../views/ManagerReport/ManagerReport.vue";
import CompanyReport from "../views/CompanyReport/CompanyReport.vue";

import Error404 from "../views/auth/error/Error404.vue";
import Error500 from "../views/auth/error/Error500.vue";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
  },
  {
    path: "/report/:reportId?",
    name: "Report",
    component: Report,
    props: true,
  },
  {
    path: "/report/subdomain/:reportId/:domainTitle",
    name: "Subdomain",
    component: Subdomain,
    props: true,
  },
  {
    path: "/login/:reportId?",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/pathways/:reportId?",
    name: "Pathways",
    component: Pathways,
    props: true,
  },
  {
    path: "/survey/:surveyId?",
    name: "Survey",
    component: Survey,
    props: true,
  },
  {
    path: "/register/:companyId?",
    name: "Register",
    component: Register,
    props: true,
  },
  {
    path: "/Login/:reportId?",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/NewPassword/:reportId?",
    name: "NewPassword",
    component: NewPassword,
    props: true,
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/crisisSupport",
    name: "CrisisSupport",
    component: CrisisSupport,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/Feedback",
    name: "Feedback",
    component: Feedback,
  },
  {
    path: "/profileInfo",
    name: "Profile Info",
    component: ProfileInfo,
  },
  {
    path: "/adminInfo",
    name: "Admin Info",
    component: AdminInfo,
  },
  {
    path: "/Objectives",
    name: "Objectives",
    component: AdminInfoObjectiveMapping,
  },
  {
    path: "/Interventions",
    name: "Interventions",
    component: AdminInfoInterventionMapping,
  },
  {
    path: "/managerReport",
    name: "Manager Report",
    component: ManagerReport,
  },
  {
    path: "/companyReport",
    name: "Company Report",
    component: CompanyReport,
  },
  {
    path: "/authentication/error/error404",
    name: "Error Error404",
    component: Error404,
  },
  {
    path: "/authentication/error/error500",
    name: "Error Error500",
    component: Error500,
  },

  { path: "/:pathMatch(.*)*", component: Error404 },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from) => {
  const user = cookies.get("lumienUser");

  if (
    to.path.includes("/login") ||
    to.path.includes("/survey") ||
    to.path.includes("/register") ||
    to.path.includes("/newPassword") ||
    to.path.includes("/authentication") ||
    to.path.includes("/forgotPassword")
  ) {
    return true;
  } else {
    if (!user)
      return {
        path: "/login",
      };
  }

  const userLevel = cookies.get("ul");

  if (to.fullPath == "/admininfo") {
    if (userLevel == "2") return true;
    return false;
  }

  if (to.fullPath == "/managerinfo") {
    if (userLevel == "2" || userLevel == "1") return true;
    return false;
  }

  return true;
});

export default router;
