<!-- eslint-disable vue/first-attribute-linebreak -->
<template>
  <div class="py-4 container-fluid">
    <h2 class="container name-section">Welcome back {{ obj.firstName }}</h2>
    <div class="row">
      <div class="col-xl-5 mt-4">
        <wellbeing-scord-card :score="obj.wellbeingScore" :score-date="obj.wellbeingScoreDate" :chart-data="obj.chartData"
          :loaded="obj.loaded"></wellbeing-scord-card>
      </div>
      <div class="col-xl-7 mt-4">
        <recommendations-card :recommendations="obj.recommendations" :number-of-recs="3" :loaded="obj.loaded"></recommendations-card>
        <argon-button  onclick="location.href='/pathways'" class="loadmore">show more recommendations</argon-button>
      </div>
    </div>
    <div class="mt-4 row spaced">
      <div class="col-lg-7 ms-auto">
        <check-in-card></check-in-card>
      </div>
      <div class="mt-4 col-lg-5 mt-lg-0">
        <find-support-card></find-support-card>
      </div>
    </div>
    <div class="row">
      <div v-for="domain in obj.domains" :key="domain.title" class="col-md-6">
        <domain-card :score="domain.score" :loaded="obj.loaded" :score-date="domain.scoreDate" :title="domain.title"
          :chart-data="domain.history" :description="domain.description" :recommendations="domain.recommendations"
          :subdomains="domain.subdomains" :report-id="obj.reportId"></domain-card>
      </div>
    </div>
  </div>
</template>

<script>

import CheckInCard from "./components/CheckInCard.vue";
import FindSupportCard from "./components/FindSupportCard.vue";
import RecommendationsCard from "@/components/RecommendationsCard.vue";
import WellbeingScordCard from "./components/WellbeingScordCard.vue";
import DomainCard from "./components/DomainCard.vue";
import { ReportService } from '@/api/reportservice';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";
const { cookies } = useCookies();


// import { RecommendationType } from "@/api/models/recommendationType";



export default {
  name: "Report",
  components: {
    CheckInCard,
    FindSupportCard,
    RecommendationsCard,
    WellbeingScordCard,
    DomainCard, 
    ArgonButton
  },
  props: {
    reportId: String,
    subdomainId: String
  },

  setup(props) {
    const obj = reactive({
      loaded: false,
      chartData: [],
      chartOptions: {
        responsive: true
      },
      wellbeingScore: 0,
      wellbeingScoreDate: "",
      reportId: props.reportId,
      subdomainId: props.subdomainId,
      recommendations: [],
      domains: [],
      firstName: "",
    });

    onMounted(() => {
      var user = cookies.get("lumienUser");

      obj.firstName = user.firstName;

      if (obj.reportId === "") {
        obj.reportId = user.reportId;
        if (obj.reportId === "") {
          console.log("Need to find the report Id")
        }
      }
      ReportService.getReport(obj.reportId).then((data) => {
        obj.wellbeingScore = data.wellbeingScore;
        obj.wellbeingScoreDate = data.reportDate;
        obj.chartData = data.wellbeingScoreHistory;
        obj.recommendations = data.recommendations;
        obj.loaded = true;
        obj.domains = data.domainsDTO;
      });
    });

    return { obj, props }

  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>
h2 {
  color: #fff;
}
</style>