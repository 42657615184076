export class UserDetailsResponse {
  firstName: string | null = "";
  lastName: string | null = "";
  email: string | null = "";

  gender: string | null = "";
  genderOptions: string[] = [];

  ethnicity: string | null = "";
  ethnicityOptions: string[] = [];

  sexuality: string | null = "";
  sexualityOptions: string[] = [];

  openSexuality: string | null = "";
  openSexualityOptions: string[] = [];

  religion: string | null = "";
  religionOptions: string[] = [];

  practicingReligion: string | null = "";
  practicingReligionOptions: string[] = [];

  dateOfBirth: string | null = "";
  postcode: string | null = "";

  //Relationships

  relationshipStatus: string | null = "";
  relationshipStatusOptions: string[] = [];

  dependants: string | null = "";
  dependantsOptions: string[] = [];

  //Work and Education

  companyName: string | null = "";
  teamName: string | null = "";

  salary: string | null = "";
  salaryOptions: string[] = [];

  education: string | null = "";
  educationOptions: string[] = [];

  jobTitle: string | null = "";

  experience: string | null = "";
  experienceOptions: string[] = [];

  remote: string | null = "";
  remoteOptions: string[] = [];
}
