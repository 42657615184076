export class MissionVisionCollectionDTO {
  organisationId = 0;
  companyMission = "";
  companyVision = "";
  companyPurpose = "";
  reportIdString = "";
  companyValues = [{}];

  public constructor() {
    this.organisationId = 0;
    this.companyMission = "";
    this.companyVision = "";
    this.companyPurpose = "";
    this.reportIdString = "";
    this.companyValues = [{ cValue: "" }];
  }
}
