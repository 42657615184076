<template>
  <div class="container-fluid ">
   
        <company-reports />
      
  </div>
</template>

<script>

import CompanyReports from "./components/CompanyReports.vue";
export default {
  name: "CompanyReport",
  components: {
    CompanyReports,
  },
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(95, 36, 159, 0.8) !important;
  backdrop-filter: blur(10px) !important;
}


</style>