<template>
  <div v-if="!obj.loaded">
    Loading, please wait.....
  </div>
  <div v-if="obj.loaded" class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-8 mt-4 recs">
        <recommendations-card
:recommendations="obj.recommendations" :number-of-recs="300"
          :loaded="obj.loaded"></recommendations-card>
      </div>
    </div>
  </div>
</template>

<script>
import RecommendationsCard from "@/components/RecommendationsCard.vue";
import { ReportService } from '@/api/reportservice';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {

  name: "Pathways",
  components: {
    RecommendationsCard,
  },
  props: {
    reportId: String
  },
  setup(props) {
    const obj = reactive({
      loaded: false,
      chartData: [],
      chartOptions: {
        responsive: true
      },
      wellbeingScore: 0,
      wellbeingScoreDate: "",
      reportId: props.reportId,
      recommendations: [],
      domains: [],
    });

    onMounted(() => {

      if (obj.reportId === "") {
        var user = cookies.get("lumienUser");
        obj.reportId = user.reportId;

        if (obj.reportId === "") {
          console.log("Need to find the report Id")
        }
      }
      ReportService.getReport(obj.reportId).then((data) => {        
        obj.recommendations = data.recommendations;
        obj.loaded = true;
      });
    });

    return { obj, props }

  },

};
</script>
