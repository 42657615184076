<template>
  <strong>About You</strong>
</template>

<script>
export default {
  name: "MetadataQuestionHeader",
  components: {},
}
</script>
