<template>
  <div class="container-fluid ">
    <div class="row">
      <div class="col-lg-12 mt-lg-0 mt-4">
        <manager-reports />
      </div>
    </div>
  </div>
</template>

<script>

import ManagerReports from "./components/ManagerReports.vue";


export default {
  name: "ManagerReport",
  components: {
    ManagerReports
  },
  props: {},

  setup() {
    return {}
  },

  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style scoped>
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(95, 36, 159, 0.8) !important;
  backdrop-filter: blur(10px) !important;
}
</style>