import { ErrorDefinition } from "./models/errorresponse";
import { ApiError } from "./models/apierror";
import { Organisation } from "./models/organisation";
import { URLLocations } from "./models/urlLocations";

const urlLocations = new URLLocations();
const reportGeneratorURL = urlLocations.reportGeneratorURL;

export class RegistrationService {
  public static submitRegistration(
    emailAddress: string,
    organisation?: string,
    inviteCode?: string,
    password?: string,
    firstName?: string,
    lastName?: string
  ): Promise<void> {
    const reportUrl = `${reportGeneratorURL}/api/v1/user/registration`;

    return fetch(reportUrl, {
      method: "POST",
      body: JSON.stringify({
        EmailAddress: emailAddress,
        OrganisationId: organisation,
        InviteCode: inviteCode,
        Password: password,
        FirstName: firstName,
        LastName: lastName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(null);
        }
        return response.json() as Promise<ErrorDefinition>;
      })
      .then((data) => {
        if (data === null) {
          return Promise.resolve();
        } else {
          const error: ErrorDefinition = data;

          throw new ApiError(
            error.title ?? "Error",
            error.detail ?? "Something went wrong, please try again",
            error.status
          );
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }

  public static getOrganisationDetails(
    organisation: string
  ): Promise<Organisation> {
    const organisationUrl = `${reportGeneratorURL}/api/v1/organisation/${organisation}`;
    return fetch(organisationUrl, {
      method: "GET",
    })
      .then((response) => {
        return response.json() as Promise<Organisation>;
      })
      .then((data) => {
        try {
          return data;
        } catch (err) {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      })
      .catch((err) => {
        if (err instanceof ApiError) {
          throw err;
        } else {
          throw new ApiError(
            "Error",
            "Something went wrong, please try again."
          );
        }
      });
  }
}
