<template>
  <div class="">
    <div class="signup-container d-flex justify-content-center">
      <div class="signup-content">
        <div style="min-height: 100px">
          <img v-if="CompanyMode" class="logo" :src="CompanyLogo" />
        </div>
        <img class="logo" src="https://lumien.io/wp-content/uploads/2019/11/Lumien-Logo-White-NoSpace.png">
        <div id="signup-description">
          <h1 style="color:#fff;">Forgot your password?</h1>

        </div>
        <div v-if="!sentEmail" id="signup-form">
          <div class="">
            <label class="uk-form-label" for="signup-email"> enter your email address to recieve a password reset
              link</label>
            <div class="">
              <div class="uk-inline">
                <input id="signup-email" v-model="obj.email" class="uk-input uk-form-width-large" type="email"
                  placeholder="Enter your email address" />
              </div>
            </div>


            <div v-if="obj.registrationError" class="uk-padding uk-padding-remove-left">
              <label class="uk-form-label uk-padding">
                {{ obj.registrationError }}
              </label>
            </div>

            <div class="uk-align-left">
              <button id="login-button" class="uk-button uk-button-default uk-button-large" @click="OnSubmit">
                Send Email
              </button>
            </div>
            <div id="privacy-policy">
              By logging in, you confirm that you've read and accepted our
              <a href="https://www.lumien.io/privacy-policy-3/" target="_blank">Privacy Policy</a>.
            </div>
          </div>
        </div>
        <div v-else>
          <p>
            Thank you, if an account has been found with that email address an
            email with instructions to change your password will be sent.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { reactive } from 'vue';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();


export default {
  name: "ForgotPassword",
  components: {},

  props: {},

  setup(props) {
    const obj = reactive({
      registrationError: null,      
    });

    return { props, obj }
  },
  mounted() {
    cookies.remove("lumienUser");
    cookies.remove("ul");
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
    //this.toggleDefaultLayout();
    document.getElementsByTagName("body")[0].classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showNavbar = true;
    //this.toggleDefaultLayout();
  },

  methods: {
    OnSubmit() {
      this.obj.loading = true;
      this.RegistrationError = null;

      var auth = getAuth();
      sendPasswordResetEmail(auth, this.obj.email)
        .then(() => {
          this.obj.registrationError = "An email has been sent to this address, please click the link in this email to reset your password."
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);

          const errorMessage = error.message;
          console.log(errorMessage);

          this.obj.registrationError = "Your login details are incorrect, please check and try again.";

          cookies.remove("lumienUser");
          cookies.remove("ul");
          this.loading = false;
        });
    },
  }
}
</script>
<style scoped>
.col1,
.col2,
.max-width {
  border-radius: 20px;
  box-sizing: border-box;
  min-height: 75px;
  margin: 10px;
}

.col1 {
  flex-grow: 1;
  margin: 0 0 50px 0;
}

.col2 {
  flex-grow: 2;
}

.max-width {
  max-width: 50%;
  margin: 0 0 125px 0;
}

p {
  font-size: 1rem;
  display: block;
  margin: 10px 0 25px 0;
}

strong {
  font-size: 1.25rem;
  font-weight: 500;
  display: block;
  margin: 10px 0;
}

h1 {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 400;
  display: block;
  margin: 10px 0;
}

.uk-form-label {
  font-size: 1rem;
  color: #fff;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  letter-spacing: 1.1rem,
}

.uk-form-width-large {
  width: 100%;
}


button {
  border: 2px solid #E63888;
  border-radius: 40px;
  display: block;
  background-color: #E63888;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  margin: 0.25rem auto;
  padding: 10px 75px;

}

button:hover {
  background-color: #622398;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

button:active {
  transform: scale(0.9);
}

#privacy-policy {
  padding: 15px 0;
  font-size: 0.75rem;
}

footer {
  grid-column: 1/5;
  grid-row: 6;
  margin: 100px 0;
  align-self: center;
}

#forgot-button {
  border: none;
  display: block;
  background-color: transparent;
  font-size: 0.75rem;
  font-weight: 200;
  color: #fff;
  text-decoration: underline;
  margin: 5rem auto 8rem;
}

input {
  border-radius: 8px;
  padding: 16px;
  border: none;
  margin: 0px 0px 16px 0px;
  font-family: Muli, Helvetica, Arial, Lucida, sans-serif;
}
</style>
