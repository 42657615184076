<template>
  <h1>Your Role at {{ companyName }}</h1>
</template>

<script>
export default {
  name: "OrganisationQuestionHeader",
  components: {},
  props: {
    companyName: {
      type: String,
      default: ""
    }
  }
}
</script>
