<template>
  <div v-if="!obj.loaded">
    Loading, please wait....
  </div>
  <div v-if="obj.loaded" id="profile" class="card card-body mt-4 profile name-info">
    <div class="h-100">
      <h5 class="mb-1 font-weight-bolder">User Management</h5>
      <p class="support-text">Change the permisions for your employees. Admin's will be able to see company reports,
        change user roles and update company settings. Managers will be able to view manager reports if your organisation
        has them enabled. </p>
    </div>
    <div class="row table-title">
      <div class="col-sm-3 col-6">
        Name
      </div>
      <div class="col-sm-7 col-6">
        Email
      </div>
      <div class="col-sm-2 col-8">
        User Level
      </div>
    </div>
    <div class="row ">
      <div v-for="user in obj.userData" :key="user" class="row user-info">
        <div class="col-sm-3 col-6">
          <label class="form-label mt-2">{{ user.name }}</label>
        </div>
        <div class="col-sm-7 col-6">
          <label class="form-label mt-2">{{ user.email }}</label>
        </div>
        <div class="col-sm-2 col-8">
          <select id="choice-userlevel" v-model="user.userLevel" class="form-control" name="choices-userlevel">
            <option :value="0">Normal</option>
            <option :value="1">Manager</option>
            <option :value="2">Admin</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <argon-button class="float-end mt-6 mb-0" color="dark" variant="gradient" size="md"
        @click="SaveAnswers()">Save</argon-button>
    </div>
  </div>
</template>

<script>
import { UserService } from '@/api/userservice';
import { reactive, onMounted } from 'vue';
import { useCookies } from "vue3-cookies";
import ArgonButton from "@/components/ArgonButton.vue";
const { cookies } = useCookies();

export default {
  name: "SideNavItem",
  components: {
    ArgonButton,
  },

  props: {},
  setup(props) {
    const obj = reactive({
      userData: [],
      loaded: false,
    });
    var user = cookies.get("lumienUser");
    var ul = cookies.get("ul");

    onMounted(() => {
      UserService.getUserLevels(user.organisationId, ul).then(response => {
        obj.userData = response;
        obj.loaded = true;
      });
    });
    return { props, obj };
  },

  data() {
    return {
      config: {
        allowInput: true,
        dateFormat: "d/m/Y"
      },
    };
  },
  methods: {

    SaveAnswers() {
      UserService.setUserLevel(this.obj.userData).then(() => {
        this.$swal({
          title: "Saved!",
        });
      })
    },
  },
}

</script>


<style scoped>
.card,
.card-header {
  background-color: #F7F5F3;
  color: #4E4B48;
}

.text-muted,
.text-muted ul,
.text-muted ul li,
.text-sm {
  font-size: 0.75rem !important;
  color: #E63888 !important;
  font-style: italic;
}

.btn {
  max-width: 300px;
  border: 2px solid #E63888;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin: 16px auto;
  display: block;
  background-color: #E63888;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btn:hover:enabled {
  background-color: #E63888;
  color: #fff;
  transform: scale(0.95);
  transition: 0.2s;
}

.btn:active:enabled {
  transform: scale(0.9);
}

.btn:disabled {
  background-color: #DDE5ED;
  border: 2px solid #DDE5ED;
  user-select: none;
  font-family: "Fredoka One", sans-serif;
  border-radius: 50px;
  font-size: 0.875rem;
  padding: 16px 64px;
  margin-bottom: 8px;
  cursor: not-allowed;
  color: #4E4B48;
}

.bg-gradient-dark {
  background-color: #E63888 !important;
  background-image: none !important;
}

.name-info {
  padding: 1.5rem;
}

.limitedHeight {
  max-height: 300px;
  overflow-y: scroll;
}

.user-info {
  padding: 8px 0px !important;
  border-bottom: 1px solid #FFE7E7;
}

.table-title {
  border-bottom: 1px solid #FFE7E7;
  text-align: left;
  font-weight: 600;
  font-size: 0.875rem;
}


.form-control:after {
  content: '>';
  color: #000;
  right: 11px;
  top: 6px;
  height: 34px;
  padding: 15px 0px 0px 8px;
  border-left: 1px solid #000;
  position: absolute;
  pointer-events: none;
}

.support-text {
  font-size: 0.875rem;
  width: 50%;
}
</style>
