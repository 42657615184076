<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4 col-lg-12 mb-lg-0">
          <div
            class="text-sm copyright"
            :class="$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            Copyright ©
            {{ new Date().getFullYear() }}
            <a
              href="https://lumien.io"
              class="font-weight-bold"
              target="_blank"
              >Evolyst Ltd | www.lumien.io</a
            >            
          </div>
        </div>       
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
<style scoped>
.copyright, .text-muted, .text-muted a, .text-lg-end .text-lg-start{
  opacity:0.75;
  font-weight:100 !important;
  font-size:0.75rem !important;
  text-align:right !important;
}

.footer{
  margin:2rem auto;
  width:100%;
  display:block;
  text-align:right;
  color:#fff;
}

.footer a{
  color:#fff;
}
</style>